import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { getToken } from "../utils";
import { Loader } from "../utils/helpers";

const RedirectHOC = (ComposedComponent) => {
  const Redirect = ({ ...rest }) => {
    const [isPageAccessible, setIsPageAccessible] = useState(false);
    const history = useHistory();
    const token = getToken("access_token");

    useEffect(() => {
      if (token) history.push("/");
      else setIsPageAccessible(true);
    }, []);

    return (
      <>
        {!isPageAccessible ? (
          Loader(!isPageAccessible)
        ) : (
          <ComposedComponent {...rest} />
        )}
      </>
    );
  };
  return Redirect;
};

export default RedirectHOC;
