import React, { useState } from "react";

import PlanImage from "../../../assets/images/plan-image.png";
import SaveIcon from "../../../assets/icons/save-icon.svg";
import StarIcon from "../../../assets/icons/star.svg";
import CartDetailsModal from "../../myCart/myCartDetails/CartDetailsModal";
import { currencySign, isSubscriptionType } from "../../../utils/helpers";

import "./lewinPlans.scss";

export default function LewinPlans({ trainersPlans, handleAddToCart ,getWorkoutPlans}) {
  const [isOpen, setIsOpen] = useState(false);
  const [planDetails, setPlanDetails] = useState({});

  const handlePurchasePlan = (e, plan) => {
    e.preventDefault();
    setIsOpen(true);
    setPlanDetails(plan);
  };

  return (
    <div >
      {trainersPlans?.length > 0 && (
        <div className="lewin-plans-all-content-alignment-for-section">
          <div className="container">
            <div className="page-title">
              <p>Jimmy Lewin’s Plans</p>
            </div>
            <div className="grid">
              {trainersPlans?.map((item) => (
                <div className="grid-col">
                <div className="grid-items">
                  <div className="card-image">
                    <img src={item.bannerUrl2 || PlanImage} alt="PlanImage" />
                  </div>
                  <div className="blur-div"></div>
                  {/* <div className="save-button">
                    <img src={SaveIcon} alt="SaveIcon" />
                  </div> */}
                  <div className="card-details-alignment">
                    <div className="all-content-align">
                      {/* <div className="rating-alignment">
                        <img src={StarIcon} alt="StarIcon " />
                        <img src={StarIcon} alt="StarIcon " />
                        <img src={StarIcon} alt="StarIcon " />
                        <img src={StarIcon} alt="StarIcon " />
                        <img src={StarIcon} alt="StarIcon " />
                      </div> */}
                      <h5>{item.title}</h5>
                      {isSubscriptionType() && (
                        <>
                          <div className="two-text">
                            <p>
                              Week <span>{item.weeks}</span>
                            </p>
                            {/* <p>
                          {" "}
                          Classes <span>32</span>
                        </p> */}
                          </div>
                          <div className="last-text">
                            <p>{item.name}</p>
                          </div>
                        </>
                      )}
                      {!isSubscriptionType() && (
                        <div className="price-button-alignment">
                          <div className="price-alignment">
                          {item?.discount > 0 ? (
                              <h6>
                                {currencySign()}
                                {item?.finalPrice}
                                &nbsp;&nbsp;
                                <s style={{color:"#7e818c"}}>
                                  {currencySign()}
                                  {item?.basePrice}
                                </s>
                                &nbsp;
                                {/* ({item?.discount}% OFF) */}
                              </h6>
                            ) : (
                              <h6>
                                {currencySign()}
                                {item?.basePrice}
                              </h6>
                            )}{" "}                          </div>
                          <div className="buy-cancle-button-alignment">
                            <>
                              <button onClick={(e) => handlePurchasePlan(e, item)}>Buy Now</button>
                              <button onClick={(e) => handleAddToCart(e, item?.planID)}>Add to cart</button>
                            </>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {isOpen && <CartDetailsModal planDetails={planDetails} setIsOpen={setIsOpen} getWorkoutPlans={getWorkoutPlans}/>}
    </div>
  );
}
