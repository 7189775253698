import React from "react";

import CalendreIcon from "../../../assets/icons/calender.svg";
import TimerIcon from "../../../assets/icons/time-fill.svg";
import VideoIcon from "../../../assets/icons/video-icon.svg";
import HomeIcon from "../../../assets/icons/home-icon.svg";

import "./planSteper.scss";
import { useHistory } from "react-router";

export default function PlanSteper({ planDetails }) {
  const history = useHistory();

  return (
    <div>
      <div className="plan-steper-all-content-alignment">
        <div className="container-md-4">
          <div className="stper-grid">
            <div className="stper-grid-items">
              <div className="icon-center-alignment">
                <div className="icon-design">
                  <img src={CalendreIcon} alt="CalendreIcon" />
                </div>
              </div>
              <p>{planDetails?.weeks} weeks</p>
            </div>
            <div className="stper-grid-items">
              <div className="icon-center-alignment">
                <div className="icon-design timer-ic">
                  <img src={TimerIcon} alt="TimerIcon" />
                </div>
              </div>
              <p>20 Minutes</p>
            </div>
            <div className="stper-grid-items">
              <div className="icon-center-alignment">
                <div className="icon-design">
                  <img src={VideoIcon} alt="VideoIcon" />
                </div>
              </div>
              <p>16 Class</p>
            </div>
            <div className="stper-grid-items">
              <div className="icon-center-alignment">
                <div className="icon-design" onClick={() => history.push("/")}>
                  <img src={HomeIcon} alt="HomeIcon" />
                </div>
              </div>
              <p>Home</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
