import React, { useEffect, useState } from "react";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { toast } from "react-hot-toast";

import api from "../../../utils/api";
import { bindNameInput, clientId, Loader } from "../../../utils/helpers";
import warning from "../../../assets/icons/warning.svg";
import { ItemFromPayload, setItemToLocal, setToken } from "../../../utils";
import ConditionsModal from "../../conditionsModal";

import "./sign-up.scss";

export default function Signup({ handleNext }) {
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    clientId: clientId(),
    userLevel: 4,
    loginType: 0,
    deviceType: 0,
    channel: 1,
  });
  const [acceptmodal, setAcceptmodal] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [paaswordStrength, setPaaswordStrength] = useState(true);

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    if (e.target.name === "password") {
      setPaaswordStrength(strongRegex.test(e.target.value));
    }
  };

  const getUserLocationFromAPI = async () => {
    try {
      const response = await axios.get(
        `https://api.ipgeolocation.io/ipgeo?apiKey=9bd923c27a9e407fbbdea17003996c3c`
      );
      let countryName = response?.data?.country_name.toUpperCase();
      setUserInfo({
        ...userInfo,
        country: countryName,
        countryCode: response?.data?.country_code2,
      });
    } catch (error) {
      // setLoading(false);
    }
  };

  useEffect(() => {
    getUserLocationFromAPI();
  }, []);

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true);
      api
        .post("authentication/signup", userInfo)
        .then((response) => {
          if (response?.data?.success) {
            // const userInfo = ItemFromPayload(response, "payload");
            const userInfo = response?.data?.payload;
            setToken(userInfo?.authenticationToken?.token);
            setItemToLocal("user", userInfo?.user);
            setItemToLocal("isQuestioned", userInfo?.user?.isQuestioned);
            setItemToLocal("isWeightUpdated", userInfo?.user?.isWeightUpdated);
            setLoading(false);
            toast.success("User successfully signed up!");
            setAcceptmodal(true);
          } else {
            setLoading(false);
            toast.error(response?.data?.error);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response?.data?.error);
        });
    }
  };

  var strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validate = () => {
    let isFormValid = true;
    if (userInfo.name?.trim() === "" || !userInfo.name) {
      isFormValid = false;
      toast.error("Please enter your name!");
    }
    if (!EMAIL_REGEX.test(userInfo.email)) {
      isFormValid = false;
      toast.error("Please enter valid email!");
    }
    if (!strongRegex.test(userInfo?.password)) {
      isFormValid = false;
      toast.error("Please enter valid password!");
    }
    if (!privacy) {
      isFormValid = false;
      toast.error("Please check terms & conditions");
    }

    return isFormValid;
  };

  const handleOnPhoneInput = (value, data, event, formattedValue, isValid) => {
    setUserInfo({
      ...userInfo,
      phone: formattedValue,
      country: data?.name.toUpperCase(),
      countryCode: data?.countryCode?.toUpperCase(),
    });
  };

  const handleOnRadioButton = (e, id) => {
    setUserInfo({ ...userInfo, channel: id });
  };

  const handleOnSendEmail = (e) => {
    setUserInfo({ ...userInfo, emailsPermission: e.target.checked });
  };
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      Width: 500,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      padding: "20px",
    },
  });

  const [passwordshown, setPasswordshown] = useState(false);
  const togglepasswordshoen = () => {
    setPasswordshown(passwordshown ? false : true);
  };

  const getText = () => {
    return (
      <>
        <span style={{ color: "white", fontSize: "16px" }}>
          Your password should:
        </span>
        <ul
          style={{
            color: "white",
            width: "250px",
            fontSize: "14px",
            padding: "8px 0 0 24px",
          }}
        >
          <li style={{ padding: "8px 0" }}>be at least 8 characters long</li>
          <li style={{ padding: "8px 0" }}>
            Your input should contain at least 1 special character
          </li>
          <li style={{ padding: "8px 0" }}>
            Your input should contain at least 1 uppercase letters
          </li>
          <li style={{ padding: "8px 0" }}>
            Your input should contain at least 1 lowercase letters
          </li>
          <li style={{ padding: "8px 0" }}>
            Your input should contain at least 1 numbers
          </li>
        </ul>
      </>
    );
  };
  const longText = getText();

  return (
    <>
      <div>
        {Loader(loading)}
        <div className="sign-up-page-content-left-alignment">
          <div className="text-style">
            <h3>Train, Eat & Live better with Fitapp's team</h3>
            {/* <p>Start with a 7-day free trial, cancel any time.</p> */}
          </div>
          <div className="form">
            <label>
              Name <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              value={userInfo.name}
              onChange={handleChange}
              onKeyPress={bindNameInput}
            />
          </div>
          <div className="form">
            <label>Phone</label>
            <PhoneInput
              inputStyle={{
                width: "100%",
                height: "57px",
                fontFamily: "OpenSans,sans-serif",
                borderRadius: "30px",
                border: "1px solid #d0d0d0",
              }}
              dropdownStyle={{
                fontFamily: "OpenSans,sans-serif",
                width: "250px",
              }}
              buttonStyle={{
                background: "none",
                borderRadius: "30px 0 0 30px",
                backgroundColor: "unset",
                border: "1px solid #d0d0d0",
              }}
              country={userInfo?.countryCode?.toLowerCase()}
              value={userInfo.phone}
              onChange={handleOnPhoneInput}
              countryCodeEditable={false}
              autocompleteSearch={true}
            />
          </div>
          <div className="form">
            <label>
              Email <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              value={userInfo.email}
              onChange={handleChange}
            />
          </div>
          <div className="form">
            <label>
              Password <span style={{ color: "red" }}>*</span>
            </label>
            {passwordshown ? (
              <i onClick={togglepasswordshoen} class="fa-solid fa-eye"></i>
            ) : (
              <i
                onClick={togglepasswordshoen}
                class="fa-solid fa-eye-slash"
              ></i>
            )}
            <input
              type={passwordshown ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={userInfo.password}
              onChange={handleChange}
            />
            <CustomWidthTooltip title={longText} placement="bottom-start">
              <img
                src={warning}
                alt="EditImg"
                style={{
                  width: "16px",
                  position: "absolute",
                  right: "15px",
                  top: "55px",
                }}
              />
            </CustomWidthTooltip>
          </div>
          {!paaswordStrength && (
            <div className="password-strength">
              <i className="fa-solid fa-circle-exclamation"></i>
              <p>
                Please enter atleast 8 character with number, symbol, capital
                and small letter
              </p>
            </div>
          )}

          <div className="radio-input-alignment">
            <label>
              From where you know about fitapp{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <div className="account-radio-button-grid">
              <div className="account-radio-button-alignment">
                <input
                  type="radio"
                  name="acounttype"
                  value="social"
                  checked={userInfo.channel === 1}
                  onChange={(e) => handleOnRadioButton(e, 1)}
                />
                <label>Social</label>
              </div>

              <div className="account-radio-button-alignment">
                <input
                  type="radio"
                  name="acounttype"
                  value="direct"
                  checked={userInfo.channel === 2}
                  onChange={(e) => handleOnRadioButton(e, 2)}
                />
                <label>Direct</label>
              </div>
              <div className="account-radio-button-alignment">
                <input
                  type="radio"
                  name="acounttype"
                  value="referal"
                  checked={userInfo.channel === 3}
                  onChange={(e) => handleOnRadioButton(e, 3)}
                />
                <label>Referal</label>
              </div>
              <div className="account-radio-button-alignment">
                <input
                  type="radio"
                  name="acounttype"
                  value="search"
                  checked={userInfo.channel === 4}
                  onChange={(e) => handleOnRadioButton(e, 4)}
                />
                <label>Search</label>
              </div>
            </div>
          </div>

          <div className="checkbox-grid check-box-alignment">
            <div className="checkbox-grid-items">
              <input
                type="checkbox"
                id="send"
                onChange={(e) => handleOnSendEmail(e)}
              />
              <label htmlFor="send"></label>
            </div>
            <div className="checkbox-grid-items">
              <p>Send me emails with updates from Fitapp team</p>
            </div>
          </div>
          <div className="checkbox-grid check-box-alignment">
            <div className="checkbox-grid-items">
              <input
                type="checkbox"
                id="sendme"
                onChange={(e) => setPrivacy(e.target.checked)}
              />
              <label htmlFor="sendme"></label>
            </div>
            <div className="by-text">
              <p>
                By creating an account, I accept the Fitnative{" "}
                <span>
                  Privacy Policy, Terms & Conditions and Billing Terms.
                  <span style={{ color: "red" }}>*</span>
                </span>
              </p>
            </div>
          </div>

          <div className="rounded-md-button ">
            {/* {userInfo?.name &&
            userInfo?.email &&
            userInfo?.password &&
            privacy ? ( */}
            <button onClick={() => handleSubmit()}>Sign up</button>

            {/* <button style={{ opacity: 0.5 }}>Sign up</button> */}
          </div>
          <div className="account">
            <p>
              Already have an account? <a href="/login">Sign In</a>
            </p>
          </div>
        </div>
      </div>
      {acceptmodal && <ConditionsModal handleNext={handleNext} />}
    </>
  );
}
