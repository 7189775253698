import React from "react";
import { useHistory } from "react-router";
import Slider from "react-slick";
import trainerBannerUrl from "../../../assets/images/traienr-banner.png";

import { isSubscribed, isSubscriptionType } from "../../../utils/helpers";

import "./trainerScreenHerobanner.scss";

export default function TrainerScreenHerobanner({ trainers, setCurrentIndex }) {
  const history = useHistory();
  const trainerScreenHerobannerSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentIndex(current),
  };

  return (
    <div className="banner-slider">
     
        <Slider {...trainerScreenHerobannerSlider}>
          {trainers?.map((item) => {
            return (
              <div className="trainer-screen-hero-banner">
                <div className="trainer-content-alignment">
                  <h1>TRAIN with {item.name.toUpperCase()}</h1>
                  <div className="rounded-white-button">
                    {isSubscriptionType() &&  !isSubscribed() && 
                      <button
                        onClick={() => history.push("/subscription-plans")}
                      >
                        Subscribe Now
                      </button>
                    }
                  </div>
                </div>
                <div className="img_cover">
                  <img src={item.bannerUrl || trainerBannerUrl } alt="img_cover" />
                </div>
              </div>
            );
          })}
        </Slider>
      
    </div>
  );
}
