import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { getItemFromLocal, getToken, ItemFromPayload } from "../../utils";
import { clientId, isSubscribed, isSubscriptionType, Loader } from "../../utils/helpers";
import { toast } from "react-hot-toast";
import api from "../../utils/api";
import DownloadFitApp from "../home/downloadFitApp";
import MealFeaturePlan from "./mealFeaturePlan";
import RecipePlans from "./recipePlans";
import RecommendedMealPlan from "./recommendedMealPlan";

import "./mealPlan.scss";
import { getCartItemCount } from "../../redux/reducer";
import { useDispatch } from "react-redux";

export default function MealPlan() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const [loading, setLoading] = useState(false);
  const [featurePlans, setFeaturePlans] = useState([]);
  const [recipe, setRecipe] = useState([]);
  const [recommendedPlans, setRecommendedPlans] = useState([]);
  const [mealBanner, setMealBanner] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  
  
  useEffect(() => {
    getAllMealPlans();
    getAppNutritionTrainers();
  }, [])
  

  const getAppNutritionTrainers = () => {
    setLoading(true);
    api
      .get(`trainers/getAppNutritionTrainers?clientID=${clientId()}`)
      .then((res) => {
        if (res.status === 200) {
          setMealBanner(ItemFromPayload(res, "payload"));
          setLoading(false);
        }
      })
      .catch((err) => setLoading(false));
  };

  const handleAddToCart = (e, id) => {
    e.preventDefault();
    if (getToken("access_token")) {
      setLoading(true);
      api
        .post("plans/addToCart", {
          clientID: clientId(),
          planID: id,
          userID: userInfo.userId,
        })
        .then((res) => {
          if (res.data.success) {
            setLoading(false);
            dispatch(getCartItemCount(userInfo.userId));
            toast.success("Plan added to cart successfully"); 
          } else {
            setLoading(false);
            toast.error(res.data?.error);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response?.data?.error);
        });
    } else {
      history.push("/login");
    }
  };

  const getAllMealPlans = () => {
    setLoading(true);
    api
      .get(
        `plans/getMealPlans?ClientId=${clientId()}&UserId=${
          userInfo?.userId || 1
        }`
      )
      .then((response) => {
        if (response.status === 200) {
          setFeaturePlans(response?.data?.payload?.featurePlans);
          setRecipe(response?.data?.payload?.recipe);
          setRecommendedPlans(response?.data?.payload?.recommendedPlans);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {Loader(loading)}
      <div className="meal-plan-hero-banner-content-alignment">
    
          <div className="meal-banner">
            <div className="meal-banner-left-right-alignment">
              {/* <h2>Jimmy Lewin’s</h2> */}
              <h3>Train with the best fitness Coach</h3>
              <div className="rounded-white-button">
                {isSubscriptionType() ? (
                   !isSubscribed() && 
                  <button onClick={() => history.push("/subscription-plans")}>
                    Subscribe Now
                  </button>
                ) : (
                  <button onClick={() => history.push("/all-meal-plan")}>Plan Details</button>
                )}
              </div>
            </div>
          </div>
      
      </div>
      <MealFeaturePlan
        handleAddToCart={handleAddToCart}
        featurePlans={featurePlans}
        getAllMealPlans={getAllMealPlans}
      />
      <RecommendedMealPlan recommendedPlans={recommendedPlans} />
      <RecipePlans handleAddToCart={handleAddToCart} recipe={recipe} getAllMealPlans={getAllMealPlans}/>
      <DownloadFitApp />
      <div className="meal-plan-last-section-spacer"></div>
    </div>
  );
}
