import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import OutSpecializedImage from "../../../assets/images/our-specialized.png";
import LeftIcon from "../../../assets/icons/slider-left.svg";
import api from "../../../utils/api";
import { ItemFromPayload } from "../../../utils";
import { clientId } from "../../../utils/helpers";

import "./ourspecializedSection.scss";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="same-slick-icon-design right-icon-align" onClick={onClick}>
      <img src={LeftIcon} alt="LeftIcon" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="same-slick-icon-design left-icon-align" onClick={onClick}>
      <img src={LeftIcon} alt="LeftIcon" />
    </div>
  );
}

export default function OurspecializedSection({ setLoading }) {
  const [specializedPlans, setSpecializedPlans] = useState([]);

  useEffect(() => {
    getSpecializedPlans();
  }, []);

  const getSpecializedPlans = () => {
    setLoading(true);
    api
      .get(`plans/getSpecializedPlans?ClientID=${clientId()}`)
      .then((response) => {
        if (response.status === 200) {
          setSpecializedPlans(ItemFromPayload(response, "payload"));
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <div>
      <div className="our-specialized-section-all-content-alignment">
        <div className="our-specialized-section-left-content container">
          <div className="section-title">
            <h4>Our Specialized Plans</h4>
            <p>A world class team to help you smash your goals.</p>
          </div>
          {specializedPlans?.length <= 3 ? (
            <div className="slidercard-alignment">
              {specializedPlans?.map((plan) => {
                return (
                  <div>
                    <div className="main-card" style={{ width: "390px" }}>
                      <div className="card-image">
                        <img src={plan?.bannerUrl ? plan?.bannerUrl : OutSpecializedImage} alt="OutSpecializedImage" />
                        <div className="card-details">
                        <h6>{plan?.title}</h6>
                        <p>{plan?.description}</p>
                      </div>
                      </div>
                     
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Slider {...settings}>
              {specializedPlans?.map((plan) => {
                return (
                  <div>
                    <div className="main-card">
                      <div className="card-image">
                        <img src={plan?.bannerUrl ? plan?.bannerUrl : OutSpecializedImage} alt="OutSpecializedImage" />
                        <div className="card-details">
                        <h6>{plan?.title}</h6>
                        <p>{plan?.description}</p>
                      </div>
                      
                      </div>
                     
                    </div>
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
}
