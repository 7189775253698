import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";

import api from "../../utils/api";
import MyCartDetails from "./myCartDetails";
import { getItemFromLocal, getToken } from "../../utils";
import { clientId, displayRazorpay, Loader } from "../../utils/helpers";
import { getCartItemCount } from "../../redux/reducer";

import "./myCart.scss";

export default function MyCart() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const [loading, setLoading] = useState(false);
  const [cartData, setCartData] = useState([]);

  useEffect(() => {
    getCartData();
  }, []);

  const getCartData = () => {
    setLoading(true);
    api
      .get(`plans/getCart?UserID=${userInfo?.userId}`)
      .then((res) => {
        if (res.status === 200) {
          setCartData(res.data.payload);
          dispatch(getCartItemCount(userInfo?.userId));
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  };

  const handleOnDelete = (e, id) => {
    e.stopPropagation();
    setLoading(true);
    api
      .delete(`plans/removeCart?PlanID=${id}&UserID=${userInfo?.userId}`)
      .then((res) => {
        if (res.status === 200) {
          getCartData();
          toast.success("Plan removed successfully!");
          setLoading(false);
        }
      })
      .catch((err) => setLoading(false));
  };
  const isUserLoggedIn = () => {
    if (getToken()) return true;
    else history.push("/login");
  };

  const handleSubscribeNow = (e) => {
    if (getToken()) {
      displayRazorpay(e, cartData[0]?.total, '', handlePayment)
    } else {
      history.push("/login");
    }
  };

  const handlePayment = (paymentId) => {
    console.log('paymentId*********', paymentId);
    if (isUserLoggedIn()) {
    setLoading(true);
    const payload = {
      clientID: clientId(),
      userID: userInfo.userId,
      planID: cartData.map((e) => e.planID).join(","),
      isSubcription: false,
      days: 0,
    };
    api
      .post("userSubscription/SubscribeUser", payload)
      .then((res) => {
        if (res?.data?.success) {
          toast.success("Plan Purchase successfully!");
          getCartData();
          setLoading(false);
          history.push("/");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
        setLoading(false);
      });
    }
  };

  return (
    <div>
      {Loader(loading)}
      <div className="">
        <div className="whislist-banner">
          <h1>My Cart</h1>
        </div>
      </div>
      <MyCartDetails
        cartData={cartData}
        handleOnDelete={handleOnDelete}
        handlePayment={handlePayment}
        getCartData={getCartData}
        handleSubscribeNow={handleSubscribeNow}
      />
    </div>
  );
}
