import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import MealImage from "../../../assets/images/meal-image.png";
import { currencySign, isSubscriptionType } from "../../../utils/helpers";
import CartDetailsModal from "../../myCart/myCartDetails/CartDetailsModal";

import "./mealFeaturePlan.scss";

export default function MealFeaturePlan({
  handleAddToCart,
  featurePlans,
  getAllMealPlans,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [planDetails, setPlanDetails] = useState({});

  const handlePurchasePlan = (e, plan) => {
    e.preventDefault();
    setIsOpen(true);
    setPlanDetails(plan);
  };

  return (
    <div>
      <div className="meal-feature-plan-all-content-aligniment">
        <div className="container">
          <div className="page-title">
            <p>Feature Plans</p>
            <NavLink to={"/all-meal-plan"}>
              <span>See All</span>
            </NavLink>
          </div>
          <div className="grid">
            {featurePlans?.slice(0, 5).map((plan) => (
              <div className="grid-col">
              <div className="grid-items">
                <NavLink to={`/meal-plan-details?id=${plan?.planID}`}>
                  <div className="card-image">
                    <img src={plan.bannerUrl2 || MealImage} alt="MealImage" />
                  </div>
                  <div className="card-details">
                    <h4>{plan?.title}</h4>
                    {isSubscriptionType() && (
                      <div>
                        <div className="two-text">
                          <p>
                            Week <span>{plan?.weeks}</span>
                          </p>
                          <p>
                            Days <span>{plan?.days}</span>
                          </p>
                        </div>
                        <div className="last-text">
                          <p>{plan?.name}</p>
                          
                        </div>
                      </div>
                    )}
                    {!isSubscriptionType() && (
                      <div className="price-button-alignment">
                        <div className="price-alignment">
                          {plan?.discount > 0 ? (
                            <h6>
                              {currencySign()}
                              {plan?.finalPrice}
                              &nbsp;&nbsp;
                              <s style={{ color: "#7e818c" }}>
                                {currencySign()}
                                {plan?.basePrice}
                              </s>
                              &nbsp;
                              {/* ({plan?.discount}% OFF) */}
                            </h6>
                          ) : (
                            <h6>
                              {currencySign()}
                              {plan?.basePrice}
                            </h6>
                          )}{" "}
                        </div>
                        {plan?.isSubscribed ? (
                          <div className="purchase-button-alignment">
                            <button>purchased</button>
                          </div>
                        ) : (
                          <div className="buy-cancle-button-alignment">
                            <>
                              <button
                                onClick={(e) => handlePurchasePlan(e, plan)}
                              >
                                Buy Now
                              </button>
                              <button
                                onClick={(e) => handleAddToCart(e, plan.planID)}
                              >
                                Add to cart
                              </button>
                            </>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </NavLink>
              </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isOpen && (
        <CartDetailsModal
          planDetails={planDetails}
          setIsOpen={setIsOpen}
          getAllMealPlans={getAllMealPlans}
        />
      )}
    </div>
  );
}
