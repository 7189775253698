import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { ItemFromPayload, urlSearchParams } from "../../utils";
import {
  clientId,
  isSubscribed,
  isSubscriptionType,
  Loader,
} from "../../utils/helpers";
import DownloadFitApp from "../home/downloadFitApp";
import AboutSection from "./aboutSection";
import LewinMorePlans from "./lewinMorePlans";
import PlanSteper from "./planSteper";
import SubscribeNow from "./subscribeNow";
import api from "../../utils/api";

import "./planDetails.scss";

export default function PlanDetails() {
  const history = useHistory();
  const [planDetails, setPlanDetails] = useState({});
  const [currentTrainer, setCurrentTrainer] = useState({});
  const [loading, setLoading] = useState(false);
  const planId = urlSearchParams("id");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [planId]);

  useEffect(() => {
    getTrainerPlans();
  }, []);

  const getTrainerPlans = () => {
    setLoading(true);
    api
      .get(`trainers/getAppTrainers?clientID=${clientId()}`)
      .then((response) => {
        if (response.status === 200) {
          const item = ItemFromPayload(response, "payload");
          const planList = [];
          item.map((data) => data.plans.map((e) => planList.push(e)));
          const currentPlan = planList.filter(
            (e) => e.planID.toString() === planId
          );
          if (currentPlan.length > 0) {
            setPlanDetails(currentPlan[0]);
            const filteredTrainer = item.filter(
              (e) => e.trainerId === currentPlan[0].trainerID
            );
            setCurrentTrainer(filteredTrainer[0]);
          }
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {Loader(loading)}
   
        <div
          className="plan-details-banner"
          style={{ backgroundImage: planDetails?.bannerUrl }}
        >
          <div className="plan-details-content-alignment">
            <h1>{planDetails?.title}</h1>
            <p>{planDetails?.weeks} Weeks</p>
            <div className="rounded-white-button">
              {isSubscriptionType() ? (
                !isSubscribed() && (
                  <button onClick={() => history.push("/subscription-plans")}>
                    Subscribe Now
                  </button>
                )
              ) : (
                <button>View All Plans</button>
              )}
            </div>
          </div>
        </div>
      
      <PlanSteper planDetails={planDetails} />
      <SubscribeNow planDetails={planDetails} setPlanDetails={setPlanDetails} />
      <LewinMorePlans currentTrainer={currentTrainer} />
      <AboutSection currentTrainer={currentTrainer} />
      <DownloadFitApp />
      <div className="plan-details-sapcer"></div>
    </div>
  );
}
