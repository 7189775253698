import React, { useState } from "react";

import AccountDetails from "../accountDetails";
import Subscriptions from "../subscriptions";
import UserprofileSiderbar from "../userprofileSiderbar";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import "./profileDetails.scss";
import AboutFitApp from "../../aboutFitApp";
import PrivacyPolicy from "../../privacyPolicy";
import TermsConditions from "../../termsConditions";
import Faq from "../../faq";

// export default function ProfileDetails({
//   userProfileQuesAns,
//   setUserProfileQuesAns,
//   userProfile,
//   setUserProfile,
//   getOnboardingQueAns
// }) {
//   const [activeTab, setActiveTab] = useState("AccountDetailsIcon");
//   return (
//     <div>
//       <div className="user-profile-details-all-content-alignment">
//         <div className="container">
//           <div className="grid">
//             <div className="grid-items">
//               <UserprofileSiderbar
//                 activeTab={activeTab}
//                 setActiveTab={setActiveTab}
//               />
//             </div>
//             <div className="grid-items">
//               {activeTab === "AccountDetailsIcon" && (
//                 <AccountDetails
//                   userProfileQuesAns={userProfileQuesAns}
//                   setUserProfileQuesAns={setUserProfileQuesAns}
//                   userProfile={userProfile}
//                   setUserProfile={setUserProfile}
//                   getOnboardingQueAns={getOnboardingQueAns}
//                 />
//               )}
//               {activeTab === "SubscriptionsIcon" && <Subscriptions />}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="main-box-content">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="container">
   <Box
       className="user-profile-details-all-content-alignment"
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        className="tabs"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
       // sx={{ borderRight: 1, borderColor: 'divider',  }}
      >
        <Tab icon={<svg id="acount-details" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.4688 21.5771V19.5771C20.4688 18.5163 20.0473 17.4989 19.2972 16.7487C18.547 15.9986 17.5296 15.5771 16.4688 15.5771H8.46875C7.40788 15.5771 6.39047 15.9986 5.64032 16.7487C4.89018 17.4989 4.46875 18.5163 4.46875 19.5771V21.5771" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.4688 11.5771C14.6779 11.5771 16.4688 9.78629 16.4688 7.57715C16.4688 5.36801 14.6779 3.57715 12.4688 3.57715C10.2596 3.57715 8.46875 5.36801 8.46875 7.57715C8.46875 9.78629 10.2596 11.5771 12.4688 11.5771Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>}  label="Account Details" {...a11yProps (0)} />
        <Tab icon={<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.4688 22.5781C17.9916 22.5781 22.4688 18.101 22.4688 12.5781C22.4688 7.05528 17.9916 2.57812 12.4688 2.57812C6.9459 2.57812 2.46875 7.05528 2.46875 12.5781C2.46875 18.101 6.9459 22.5781 12.4688 22.5781Z" stroke="#212330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.4688 8.57812V12.5781" stroke="#212330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.4688 16.5781H12.4788" stroke="#212330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>}  label="About" {...a11yProps(1)} />
        <Tab icon={<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.46875 3.57812H20.4688C20.9992 3.57813 21.5079 3.78884 21.883 4.16391C22.258 4.53898 22.4688 5.04769 22.4688 5.57812V11.5781C22.4688 14.2303 21.4152 16.7738 19.5398 18.6492C17.6645 20.5246 15.1209 21.5781 12.4688 21.5781C11.1555 21.5781 9.85517 21.3195 8.64192 20.8169C7.42866 20.3144 6.32627 19.5778 5.39768 18.6492C3.52232 16.7738 2.46875 14.2303 2.46875 11.5781V5.57812C2.46875 5.04769 2.67946 4.53898 3.05454 4.16391C3.42961 3.78884 3.93832 3.57813 4.46875 3.57812V3.57812Z" stroke="#212330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.46875 11.5781L12.4688 14.5781L17.4688 9.57812" stroke="#212330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>} label="Privacy Policy" {...a11yProps(2)} />
        <Tab icon={<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.4688 4.57812H4.46875C3.93832 4.57813 3.42961 4.78884 3.05454 5.16391C2.67946 5.53898 2.46875 6.04769 2.46875 6.57812V20.5781C2.46875 21.1086 2.67946 21.6173 3.05454 21.9923C3.42961 22.3674 3.93832 22.5781 4.46875 22.5781H18.4688C18.9992 22.5781 19.5079 22.3674 19.883 21.9923C20.258 21.6173 20.4688 21.1086 20.4688 20.5781V13.5781" stroke="#212330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.9688 3.07835C19.3666 2.68053 19.9061 2.45703 20.4688 2.45703C21.0314 2.45703 21.5709 2.68053 21.9688 3.07835C22.3666 3.47618 22.5901 4.01574 22.5901 4.57835C22.5901 5.14096 22.3666 5.68053 21.9688 6.07835L12.4688 15.5784L8.46875 16.5784L9.46875 12.5784L18.9688 3.07835Z" stroke="#212330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>} label="Terms & Conditions" {...a11yProps(3)} />
        <Tab icon={<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.4688 2.57812H6.46875C5.93832 2.57813 5.42961 2.78884 5.05454 3.16391C4.67946 3.53898 4.46875 4.04769 4.46875 4.57812V20.5781C4.46875 21.1086 4.67946 21.6173 5.05454 21.9923C5.42961 22.3674 5.93832 22.5781 6.46875 22.5781H18.4688C18.9992 22.5781 19.5079 22.3674 19.883 21.9923C20.258 21.6173 20.4688 21.1086 20.4688 20.5781V8.57812L14.4688 2.57812Z" stroke="#212330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.4688 2.57812V8.57812H20.4688" stroke="#212330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.4688 13.5781H8.46875" stroke="#212330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.4688 17.5781H8.46875" stroke="#212330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.4688 9.57812H9.46875H8.46875" stroke="#212330" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>} label="Licences" {...a11yProps(4)} />
       
      </Tabs>
      
      <div className="tab-panels">
      <TabPanel value={value} index={0}>
        <AccountDetails />
      </TabPanel> 
      <TabPanel value={value} index={1}>
        <AboutFitApp />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PrivacyPolicy />
      </TabPanel>
      <TabPanel value={value} index={3}>
      <TermsConditions />
      </TabPanel>
      <TabPanel value={value} index={4}>
      <Faq />
      </TabPanel>
      </div>
      
     
    </Box>
    </div>
 
  );
}