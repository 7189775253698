import React, { useEffect, useState } from "react";

import api from "../../utils/api";
import AboutSection from "../planDetails/aboutSection";
import DownloadFitApp from "../home/downloadFitApp";
import TrainerFitappPlans from "./trainerFitappPlans";
import TrainerDetailsSection from "./trainerDetailsSection";
import TrainerScreenHerobanner from "./trainerScreenHerobanner";
import { ItemFromPayload } from "../../utils";
import { clientId, Loader } from "../../utils/helpers";

import "./trainerScreen.scss";

export default function TrainerScreen() {
  const [trainers, setTrainers] = useState([]);
  const [currentTrainer, setCurrentTrainer] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  
  useEffect(() => {
    getTrainerPlans();
  }, [])
  

  useEffect(() => {
    if (trainers.length > 0) {
      setCurrentTrainer(trainers[currentIndex]);
    }
  }, [currentIndex]);

  const getTrainerPlans = () => {
    setLoading(true);
    api
      .get(`trainers/getAppTrainers?clientID=${clientId()}`)
      .then((response) => {
        if (response.status === 200) {
          const item = ItemFromPayload(response, "payload");
          setTrainers(item);
          setCurrentTrainer(item[0]);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {Loader(loading)}
      <TrainerScreenHerobanner
        trainers={trainers}
        setCurrentIndex={setCurrentIndex}
      />
      <TrainerFitappPlans currentTrainer={currentTrainer} />
      <TrainerDetailsSection />
      <AboutSection currentTrainer={currentTrainer} />
      <DownloadFitApp />
      <div className="trainer-screen-bottom-alignment"></div>
    </div>
  );
}
