import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SmallblogImage from "../../../assets/images/similar-blog.png";
import "./similarBlog.scss";
import { NavLink } from "react-router-dom";

export default function SimilarBlog({ similarBlogPost, setCurrentBlogPost, currentBlogPost }) {
  const history = useHistory();

  const [openList, setOpenList] = useState(false);
  return (
    <div>
      <div className="similar-blog-all-content-alignment">
        <div className="container">
          <div className="page-title">
            <p>Similar Blogs</p>
            {/* <NavLink to={"/blog"}> */}
            {!openList && <span onClick={() => setOpenList(!openList)}>See All</span>}

            {/* </NavLink> */}
          </div>

          <div className="grid">
            {openList
              ? similarBlogPost
                  ?.filter((item) => item?.postID !== currentBlogPost?.postID)
                  ?.map((blog) => {
                    return (
                      <div
                        className="grid-items"
                        onClick={() => {
                          const currentPost = similarBlogPost?.filter((item) => item?.postID === blog?.postID);
                          history.push(`/blog-details?id=${blog.postID}`);
                          setCurrentBlogPost(currentPost[0]);
                        }}
                      >
                        <div className="card-image">
                          <img src={blog.uploads.length > 0 ? blog.uploads[0].uploadUrl : SmallblogImage} alt="SmallblogImage" />
                        </div>
                        <div className="card-details">
                          <h4>{blog.title}</h4>
                          <p>{blog.bodyText}</p>
                          {/* <span
                      onClick={() =>
                        history.push(`blog-details?id=${blog.postID}`)
                      }
                    >
                      Learn More
                    </span> */}
                        </div>
                      </div>
                    );
                  })
              : similarBlogPost
                  ?.filter((item) => item?.postID !== currentBlogPost?.postID)
                  ?.slice(0, 3)
                  ?.map((blog) => {
                    return (
                      <div
                        className="grid-items"
                        onClick={() => {
                          const currentPost = similarBlogPost?.filter((item) => item?.postID === blog?.postID);
                          setCurrentBlogPost(currentPost[0]);

                          history.push(`/blog-details?id=${blog.postID}`);
                        }}
                      >
                        <div className="card-image">
                          <img src={blog.uploads.length > 0 ? blog.uploads[0].uploadUrl : SmallblogImage} alt="SmallblogImage" />
                        </div>
                        <div className="card-details">
                          <h4>{blog.title}</h4>
                          <p>{blog.bodyText}</p>
                          {/* <span
                      onClick={() =>
                        history.push(`blog-details?id=${blog.postID}`)
                      }
                    >
                      Learn More
                    </span> */}
                        </div>
                      </div>
                    );
                  })}
          </div>
        </div>
      </div>
    </div>
  );
}
