import React from "react";
import Emailbox from "./emailBox";
import FaqSection from "./faqSection";
import "./footer.scss";
import FooterLogo from "../../assets/icons/footer-logo.svg";
import FacebookIcon from "../../assets/icons/facebook.svg";
import InstagramIcon from "../../assets/icons/insta.svg";
import TwitterIcon from "../../assets/icons/twitter.svg";
import LinkdinIcon from "../../assets/icons/linkdin.svg";
import { urlSearchParams } from "../../utils";
import { isSubscribed, isSubscriptionType } from "../../utils/helpers";
export default function Footer() {
  const theme = urlSearchParams("theme");
  return (
    <div>

    <div className="faq-section">
    <FaqSection />
        <Emailbox />
    </div>
      

      <footer style={{ borderRadius: theme == "black" ? "none" : "none" }}>
        {/* <FaqSection />
        <Emailbox /> */}
        <div className="footer-design">
          <div className="container">
            <div className="footer-logo-center-alignment">
              <img src={FooterLogo} alt="FooterLogo" />
            </div>
            <div className="footer-menu">
              <a href="/about-fitapp">About</a>
              <a href="/privacy-policy">Privacy</a>
              <a href="/blog">Blog</a>
              <a href="/license">License</a>
              <a href="/terms-conditions">Terms</a>
              {isSubscriptionType() && !isSubscribed() && <a href="/subscription-plans">Subscriptions</a>}
            </div>
            <div className="footer-last-content-alignment">
              <div className="left-content">
                <p>Copyright 2022© - dotpixelagency</p>
              </div>
              <div className="right-content">
                <span>Follow Fitapp</span>
                <div className="social-media-icon">
                  {/* <a href="https://www.instagram.com/p/CpM7isTNcqS/?utm_source=ig_web_copy_link" target="blank"> */}
                    <img src={FacebookIcon} alt="FacebookIcon" />{" "}
                  {/* </a> */}
                  <img src={InstagramIcon} alt="InstagramIcon" />
                  <img src={TwitterIcon} alt="TwitterIcon" />
                  <img src={LinkdinIcon} alt="LinkdinIcon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
