import React from 'react'
import './meetFitappTrainer.scss';
import Slider from "react-slick";
import TrainerImage from '../../../assets/images/trainer-image.png';
import DumbbleImage from '../../../assets/icons/dumbble.svg';
import weightImage from '../../../assets/images/weight.png';
import exerciseImage from '../../../assets/images/exercise.png';
import stretchingImage from '../../../assets/images/stretching.png';
import runningImage from '../../../assets/icons/running.png';
import exercisImage from '../../../assets/images/exercis.png';
export default function MeetFitappTrainer() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        autoplay: false,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        responsive: [

            {
                breakpoint: 1025,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false,
                  autoplay: true,
                },
              },

              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false,
                  autoplay: true,
                },
              },
         
          {
            breakpoint: 567,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: false,
              autoplay: true,
            },
          },
          
        ],
      };

  return (
    <div>
        <div className='meet-your-firapp-trainer'>
            <div className='container'>
                <div className='page-title'>
                    <h4>Meet your Fitapp Trainer</h4>    
                    <p>
                        Mike O’Hearn, also known as the “Titan,” is a fitness legend. Like the bodybuilding greats before him, Arnold Schwarzenegger and Lou Ferrigno, Mike holds 4 Mr. Universe titles and 
                        was voted one of the 12 greatest physiques of all time by the fitness industry. 
                    </p>
                </div>    
                <div className='line-grid'>
                    <div className='line-grid-items'>
                        <div className='line'></div>
                    </div>
                    <div className='line-grid-items'>
                        <div className='trainer-image-style'>
                            <img src={TrainerImage} alt="TrainerImage"/>
                        </div>
                    </div>
                    <div className='line-grid-items'>
                    <div className='line'></div>
                    </div>
                </div>

               
                <div className='icon-text-flex'>
                <Slider {...settings}>
                    <div className='icon-text-flex-items'>
                        <div className='icon-center-alignment'>
                            <div className='icon-design'>
                                <img src={weightImage} alt="weightImage"/>
                            </div>
                        </div>
                        <p>Power Shred</p>
                    </div>
                    <div className='icon-text-flex-items'>
                        <div className='icon-center-alignment'>
                            <div className='icon-design'>
                                <img src={DumbbleImage} alt="DumbbleImage"/>
                            </div>
                        </div>
                        <p>Dumbble at Home</p>
                    </div>
                    <div className='icon-text-flex-items'>
                        <div className='icon-center-alignment'>
                            <div className='icon-design'>
                                <img src={runningImage} alt="DumbbleImage"/>
                            </div>
                        </div>
                        <p>Treadmill</p>
                    </div>
                    <div className='icon-text-flex-items'>
                        <div className='icon-center-alignment'>
                            <div className='icon-design'>
                                <img src={exercisImage} alt="DumbbleImage"/>
                            </div>
                        </div>
                        <p>Professional Screts</p>
                    </div>
                    <div className='icon-text-flex-items'>
                        <div className='icon-center-alignment'>
                            <div className='icon-design'>
                                <img src={stretchingImage} alt="DumbbleImage"/>
                            </div>
                        </div>
                        <p>Streching Excrecise</p>
                    </div>
                    <div className='icon-text-flex-items'>
                        <div className='icon-center-alignment'>
                            <div className='icon-design'>
                                <img src={exerciseImage} alt="DumbbleImage"/>
                            </div>
                        </div>
                        <p>Streching Excrecise</p>
                    </div>
                    </Slider>
                </div>

               
                
            </div>            
        </div>
    </div>
  )
}
