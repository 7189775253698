import React from 'react'
import './myWorkout.scss';
import CardImage from "../../../assets/images/master-image.png";
import ShareIcon from "../../../assets/icons/share.svg";
export default function MyWorkout() {
  return (
    <div>
        <div className='plan-progress-my-workout-all-content-alignment'>
            <div className='container'>
                <div className='page-title'>
                    <p>My Workout</p>
                </div>
                <div className="grid">
            {[0, 1, 2].map(() => {
              return (
                <div className="grid-items">
                  <div className="card-image">
                    <div className="image-style">
                      <img src={CardImage} alt="CardImage" />
                    </div>
                    <div className="image-share-button">
                      <img src={ShareIcon} alt="ShareIcon" />
                    </div>
                  </div>
                  <div className="card-details">
                    <h4>Body Fat Loss</h4>
                   <div className='sub-text'>
                    <p>Week 16</p>
                   </div>
                   <div className='progress-bar'>
                    <div className='progress-bar-active'></div>
                   </div>
                   <div className='two-text'>
                    <p>Complete <span>40%</span></p>
                    <p>Weeks <span>40%</span></p>
                   </div>
                   
                  </div>
                </div>
              );
            })}
          </div>
            </div>
        </div>
    </div>
  )
}
