import React, { useEffect, useState } from "react";

import BlogImage from "../../../assets/images/blog.png";
import api from "../../../utils/api";
import { ItemFromPayload } from "../../../utils";
import { clientId } from "../../../utils/helpers";

import "./blog.scss";
import { useHistory } from "react-router-dom";

export default function Blog({ setLoading, userInfo }) {
  const [blogPosts, setBlogPosts] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getAllBlogPosts();
  }, []);

  const getAllBlogPosts = () => {
    setLoading(true);
    api
      .get(`blogs/GetBlogPosts?ClientID=${clientId()}&UserID=${userInfo?.userId || 18}`)
      .then((response) => {
        if (response.status === 200) {
          setBlogPosts(ItemFromPayload(response, "payload"));
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="all-blog-content-alignment">
        {blogPosts?.length > 0 && (
          <div className="container">
            <div className="blog-title">
              <h1>Fittapp Blogs</h1>
              <p>Expert tips to help you Train, Eat and Live better.</p>
            </div>
            <div className="grid">
              {blogPosts?.slice(0, 3).map((blog) => (
                <div className="grid-items" onClick={() => history.push(`/blog`)}>
                  <div className="card-image">
                    <img src={blog?.uploads?.length > 0 ? blog.uploads[0].uploadUrl : BlogImage} alt="BlogImage" />
                  </div>
                  <div className="card-details">
                    <p>{blog?.title}</p>
                    <span>{blog?.bodyText}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
