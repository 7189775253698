import React, { useState } from "react";

import ReceipeImage from "../../../assets/images/receipe.png";
import ShareIcon from "../../../assets/icons/share.svg";
import starIcon from "../../../assets/icons/star.svg";

import "./recipePlans.scss";
import { currencySign, isSubscriptionType } from "../../../utils/helpers";
import CartDetailsModal from "../../myCart/myCartDetails/CartDetailsModal";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="arrow-design right-icon-alignment" onClick={onClick}>
      <svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.63281 1.96387L1.63281 7.96387L7.63281 13.9639"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="arrow-design left-icon-alignment" onClick={onClick}>
      <svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.17187 1.13574L1.17187 7.13574L7.17188 13.1357"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default function RecipePlans({
  recipe,
  handleAddToCart,
  getAllMealPlans,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [planDetails, setPlanDetails] = useState({});

  const handlePurchasePlan = (e, plan) => {
    e.preventDefault();
    setIsOpen(true);
    setPlanDetails(plan);
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    // centerMode: true,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          nextArrow: false,
          prevArrow: false,
        },
      },
      
    ],
  };

  return (
    <div>
      <>
        <div className="receipe-plans-all-content-alignment">
          <div className="container">
            <div className="page-title">
              <p>We Have Recipe Plans for You</p>
           
          </div>
          <div className="receipe-plans-left-sider-silder">
            <div className={recipe?.length <= 3 ? " grid" : "block"}>
              {recipe?.length <= 3 ? (
                recipe?.map((plan) => (
                  <div className="grid-items" style={{ width: "400px" }}>
                    <div className="card-details-relative">
                      <div className="card-image">
                        <img
                          src={plan?.bannerUrl2 || ReceipeImage}
                          alt="ReceipeImage"
                        />
                      </div>

                      <div className="blue-div"></div>
                      <div className="card-details">
                        <div className="card-details-align">
                          <h4
                            style={{
                              whiteSpace: "nowrap",
                              width: "300px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {plan?.title}
                          </h4>
                          {/* {isSubscriptionType() && ( */}
                            <>
                              <div className="two-text">
                                <p>
                                  Week <span>{plan?.weeks}</span>
                                </p>
                              </div>
                              <div className="last-text">
                                <p >{plan?.name}</p>
                              </div>
                            </>
                          {/* // )} */}
                          {/* {!isSubscriptionType() && (
                            <div className="price-button-alignment">
                              <div className="price-alignment">
                                {plan?.discount > 0 ? (
                                  <h6>
                                    {currencySign()}
                                    {plan?.finalPrice}
                                    &nbsp;&nbsp;
                                    <s style={{ color: "#7e818c" }}>
                                      {currencySign()}
                                      {plan?.basePrice}
                                    </s>
                                    &nbsp;
                                  </h6>
                                ) : (
                                  <h6>
                                    {currencySign()}
                                    {plan?.basePrice}
                                  </h6>
                                )}{" "}
                              </div>
                              {plan?.isSubscribed ? (
                                <div className="purchase-button-alignment">
                                  <button>purchased</button>
                                </div>
                              ) : (
                                <div className="buy-cancle-button-alignment">
                                  <>
                                    <button
                                      onClick={(e) =>
                                        handlePurchasePlan(e, plan)
                                      }
                                    >
                                      Buy Now
                                    </button>
                                    <button
                                      onClick={(e) =>
                                        handleAddToCart(e, plan.planID)
                                      }
                                    >
                                      Add to cart
                                    </button>
                                  </>
                                </div>
                              )}
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <Slider {...settings}>
                  {recipe?.map((plan) => (
                    <>
                      {console.log("plan**--**", plan)}
                      <div className="grid-items">
                        <div className="card-details-relative">
                          <div className="card-image">
                            <img
                              src={plan.bannerUrl2 || ReceipeImage}
                              alt="ReceipeImage"
                            />
                          </div>

                          <div className="blue-div"></div>
                          <div className="card-details">
                            <div className="card-details-align">
                              <h4>{plan.title}</h4>
                              {/* {isSubscriptionType() && ( */}
                                <>
                                  <div className="two-text">
                                    <p>
                                      Week <span>{plan.weeks}</span>
                                    </p>
                                  </div>
                                  <div className="last-text">
                                    <p >{plan?.name}</p>

                                    {/* <p>
                                  {plan.description ||
                                    "Lorem ipsum dolor sit amet, consectetur adipi scing elit. Integer ac purus eget"}
                                </p> */}
                                  </div>
                                </>
                              {/* )} */}
                              {/* {!isSubscriptionType() && (
                                <div className="price-button-alignment">
                                  <div className="price-alignment">
                                    {plan?.discount > 0 ? (
                                      <h6>
                                        {currencySign()}
                                        {plan?.finalPrice}
                                        &nbsp;&nbsp;
                                        <s style={{ color: "#7e818c" }}>
                                          {currencySign()}
                                          {plan?.basePrice}
                                        </s>
                                        &nbsp;
                                      </h6>
                                    ) : (
                                      <h6>
                                        {currencySign()}
                                        {plan?.basePrice}
                                      </h6>
                                    )}{" "}
                                  </div>
                                  {plan?.isSubscribed ? (
                                    <div className="purchase-button-alignment">
                                      <button>purchased</button>
                                    </div>
                                  ) : (
                                    <div className="buy-cancle-button-alignment">
                                      <>
                                        <button
                                          onClick={(e) =>
                                            handlePurchasePlan(e, plan)
                                          }
                                        >
                                          Buy Now
                                        </button>
                                        <button
                                          onClick={(e) =>
                                            handleAddToCart(e, plan.planID)
                                          }
                                        >
                                          Add to cart
                                        </button>
                                      </>
                                    </div>
                                  )}
                                </div>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </Slider>
              )}
            </div>
          </div>
          </div>
        </div>
        {isOpen && (
          <CartDetailsModal
            planDetails={planDetails}
            setIsOpen={setIsOpen}
            getAllMealPlans={getAllMealPlans}
          />
        )}
      </>
    </div>
  );
}
