import React from 'react'
import './conditionsModal.scss';
import CloseIcon from '../../assets/icons/close.svg';
export default function ConditionsModal(props) {
    const {handleNext}=props
  return (
    <div>
        <div className='conditions-modal-wrapper'>
            <div className='conditions-modal'>
                <div className='conditions-modal-header'>
                </div>
                <div className='conditions-modal-body'>
                    <h4>I Accept all of the Terms and Conditions to use Fitapp</h4>
                    <p>Answer a Few Questions, and Fitapp will Better Help you achieve your Goal.</p>
                    <div className='rounded-md-button '>
                        <button onClick={()=>handleNext("onboarding")}>Agreed</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
