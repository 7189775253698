import React from "react";
import "./activePlan.scss";
import CardImage from "../../../assets/images/dish-image.png";
import ShareIcon from "../../../assets/icons/share.svg";
export default function ActivePlan() {
  return (
    <div>
      <div className="wishlust-active-plan-all-content-alignment">
        <div className="container">
          <div className="page-title">
            <p>Active Plan</p>
          </div>
          <div className="grid">
            {[0, 1, 2, 3].map(() => {
              return (
                <div className="grid-items">
                  <div className="card-image">
                    <div className="image-style">
                      <img src={CardImage} alt="CardImage" />
                    </div>
                    <div className="image-share-button">
                      <img src={ShareIcon} alt="ShareIcon" />
                    </div>
                  </div>
                  <div className="card-details">
                    <h4>Body Fat Loss</h4>
                    <div className="two-text">
                      <p>
                        Week <span>16</span>
                      </p>
                      <p>
                        {" "}
                        Classes<span>32</span>
                      </p>
                    </div>
                    <div className="last-text">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipi scing
                        elit. Integer ac purus eget
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
