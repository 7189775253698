import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import CardImage from "../../../assets/images/feature-plan-card.png";
import ShareIcon from "../../../assets/icons/share.svg";
import CartDetailsModal from "../../myCart/myCartDetails/CartDetailsModal";
import { currencySign, isSubscriptionType } from "../../../utils/helpers";

import "./featurePlanCard.scss";

export default function FeaturePlanCard({ featurePlans, handleAddToCart,getWorkoutPlans }) {
  const [isOpen, setIsOpen] = useState(false);
  const [planDetails, setPlanDetails] = useState({});

  const handlePurchasePlan = (e, plan) => {
    e.preventDefault();
    setIsOpen(true);
    setPlanDetails(plan);
  };

  return (
    <div>
      <div className="feature-plan-card-all-content-alignment">
        <div className="container">
          <div className="grid">
            {featurePlans?.length > 0 &&
              featurePlans?.map((item) => {
                return (
                  <div className="grid-col">
                  <div className="grid-items">
                    <NavLink to={`/workout-plan-details?id=${item.planID}`}>
                      <div className="card-image">
                        <div className="image-style">
                          <img
                            src={item?.bannerUrl || CardImage}
                            alt="CardImage"
                          />
                        </div>
                        {/* <div className="image-share-button">
                          <img src={ShareIcon} alt="ShareIcon" />
                        </div> */}
                      </div>
                      <div className="card-details">
                        <h4>{item?.title}</h4>
                        {isSubscriptionType() && (<div>
                          <div className="two-text">
                            <p>
                              Week <span>{item?.weeks}</span>
                            </p>
                            {/* <p>
                              Classes<span>32</span>
                            </p> */}
                          </div>
                          <div className="last-text">
                            <p>
                             {item?.name}
                            </p>
                          </div>
                        </div>)}

                        {!isSubscriptionType() && ( <div className="price-button-alignment">
                          <div className="price-alignment">
                            {/* <h6>{currencySign()}{item?.basePrice}</h6> */}
                            {item?.discount > 0 ? (
                              <h6>
                                {currencySign()}
                                {item?.finalPrice}
                                &nbsp;&nbsp;
                                <s style={{color:"#7e818c"}}>
                                  {currencySign()}
                                  {item?.basePrice}
                                </s>
                                &nbsp;
                                {/* ({item?.discount}% OFF) */}
                              </h6>
                            ) : (
                              <h6>
                                {currencySign()}
                                {item?.basePrice}
                              </h6>
                            )}{" "}
                          </div>
                          {item?.isSubscribed ? (
                          <div className="purchase-button-alignment">
                            <button>purchased</button>
                          </div>
                        ) : (
                          <div className="buy-cancle-button-alignment">
                       
                              <>
                                <button
                                  onClick={(e) => handlePurchasePlan(e, item)}
                                >
                                  Buy Now
                                </button>
                                <button
                                  onClick={(e) =>
                                    handleAddToCart(e, item.planID)
                                  }
                                >
                                  Add to cart
                                </button>
                              </>
                           
                          </div>)}
                        </div> )}
                      </div>
                    </NavLink>
                  </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {isOpen && (
        <CartDetailsModal planDetails={planDetails} setIsOpen={setIsOpen} getWorkoutPlans={getWorkoutPlans}/>
      )}
    </div>
  );
}
