import React, { useEffect } from 'react'
import MyWorkout from './myWorkout';
import './planProgressDetails.scss';
import RecentAdded from './recentAdded';
export default function PlanProgressDetails() {
  useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    })
}, [])
  return (
    <div>
        <div className='container'>
            <div className='whislist-banner'>
                <h1>My Plans Progress Details</h1>
            </div>
        </div>
        <RecentAdded/>
        <MyWorkout/>
        <RecentAdded/>
        <MyWorkout/>
        <div className='plan-progres-details-all-content-alignment'></div>


    </div>
  )
}

