import React from "react";
import { NavLink } from "react-router-dom";

import Slider from "react-slick";
import PlanCardImage from "../../../assets/images/plan-card.png";
import ArrowIcon from "../../../assets/icons/new-black.svg";

import "./trainerFitappPlans.scss";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="arrow-design right-icon-alignment" onClick={onClick}>
      {/* <img src={ArrowIcon} alt="ArrowIcon" /> */}

      <svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.63281 1.96387L1.63281 7.96387L7.63281 13.9639"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="arrow-design left-icon-alignment" onClick={onClick}>
      {/* <img src={ArrowIcon} alt="ArrowIcon" /> */}

      <svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.17187 1.13574L1.17187 7.13574L7.17188 13.1357"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

    </div>
  );
}

export default function TrainerFitappPlans({ currentTrainer }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <div>
      <div className="container">
      <div className="trainer-fill-app-plans-content-alignment">
        {currentTrainer?.plans?.length > 0 && (
          <div className="container">
            <div className="page-title">
              <h1>{currentTrainer?.name} Fitapp's Plans</h1>
              <p>{currentTrainer?.personalStory}</p>
            </div>
          </div>
        )}
        <div className="left-content-slider-align">
          {currentTrainer?.plans?.length <= 4 ? (
            <div className="slidercard-alignment">
              {currentTrainer?.plans?.map((item) => {
                return (
                  <div className="main-card">
                    <NavLink to={`/plan-details?id=${item.planID}`}>
                      <div className="card-image">
                        <img
                          src={item?.bannerUrl2 || PlanCardImage}
                          alt="PlanCardImage"
                        />
                      </div>
                      <div className="blur-div"></div>
                      <div className="card-content">
                        <div className="card-content-alignment">
                          <h4>{item?.title}</h4>
                          <span>{item?.weeks} Weeks</span>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          ) : (
            <Slider {...settings}>
              {currentTrainer?.plans?.map((item) => {
                return (
                  <div>
                    <div className="main-card">
                      <NavLink to={`/plan-details?id=${item.planID}`}>
                        <div className="card-image">
                          <img
                            src={item?.bannerUrl2 || PlanCardImage}
                            alt="PlanCardImage"
                          />
                        </div>
                        <div className="blur-div"></div>
                        <div className="card-content">
                          <div className="card-content-alignment">
                            <h4>{item?.title}</h4>
                            <span>{item?.weeks} Weeks </span>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
      </div>
      </div>
    
    </div>
  );
}
