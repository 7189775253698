import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { getToken } from "../utils";
import { Loader } from "../utils/helpers";

const AuthHOC = (ComposedComponent) => {
  const Auth = ({ ...rest }) => {
    const [isPageAccessible, setIsPageAccessible] = useState(false);
    const history = useHistory();
    const token = getToken("access_token");
    useEffect(() => {
      if (token) setIsPageAccessible(true);
      else history.push("/login");
    }, []);
    return (
      <>
        {!isPageAccessible ? (
          Loader(!isPageAccessible)
        ) : (
          <ComposedComponent {...rest} />
        )}
      </>
    );
  };
  return Auth;
};

export default AuthHOC;
