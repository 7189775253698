import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

import api from "../../../utils/api";
import { setItemToLocal } from "../../../utils";
import { Loader } from "../../../utils/helpers";

import "./personalDetails.scss";

export default function PersonalDetails({ userInfo }) {
  const history = useHistory();
  const [dropdownHidden1, setDropdownHidden1] = useState(false);
  const [unit, setUnit] = useState("lbs");
  const [inch, setInch] = useState("");
  const [feet, setFeet] = useState("");
  const [weight, setWeight] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOnboardingData = () => {
    setLoading(true);
    let payload = {
      userID: userInfo?.userId,
      weight: weight.concat(unit),
      height: feet.concat("ft", inch, "''"),
    };

      api
        .post("authentication/UpdateUserWeightHeight", payload)
        .then((response) => {
          history.push("/");
          toast.success("Profile updated successfully!");
          setItemToLocal("isWeightUpdated", true);
          setLoading(false);
        }).catch((error) =>{
          setLoading(false);
          toast.error(error.response?.data?.error)
        })
  };

  return (
    <>
      {Loader(loading)}
      <div>
        <div className="personal-all-details-content-alignment">
          <div className="heihgt-text-style">
            <span>Height</span>
          </div>
          <div className="height-grid">
            <div className="height-grid-items">
              <div className="main-input">
                <div className="input-relative">
                  <input
                    type="number"
                    placeholder="e.g 5"
                    value={feet}
                    onWheel={() => document.activeElement.blur()}
                    onKeyDown={(e) =>
                      e.key?.includes("Arrow") && e.preventDefault()
                    }
                    onChange={(e) =>
                      e.target.value > 8
                        ? e.preventDefault()
                        : setFeet(e.target.value)
                    }
                  />
                  <div className="right-text-alignment">
                    <span>Feet</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="height-grid-items">
              <div className="main-input">
                <div className="input-relative">
                  <input
                    type="number"
                    placeholder="e.g 6"
                    value={inch}
                    onWheel={() => document.activeElement.blur()}
                    onKeyDown={(e) =>
                      e.key?.includes("Arrow") && e.preventDefault()
                    }
                    onChange={(e) =>
                      e.target.value > 12
                        ? e.preventDefault()
                        : setInch(e.target.value)
                    }
                  />
                  <div className="right-text-alignment">
                    <span>Inch</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="heihgt-text-style">
            <span>Weight</span>
          </div>
          <div className="weight-main-relative">
            <div className="main-input">
              <div className="input-relative">
                <input
                  type="number"
                  placeholder="e.g 159 Ibs"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) =>
                    e.key?.includes("Arrow") && e.preventDefault()
                  }
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
                <div
                  className="right-text-alignment"
                  onClick={() => setDropdownHidden1(!dropdownHidden1)}
                >
                  <span>{unit}</span>
                  <svg
                    width="11"
                    height="6"
                    viewBox="0 0 11 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 0.75L5.5 5.25L10 0.75"
                      stroke="#757575"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div
                className={
                  dropdownHidden1
                    ? "dropdown-design dropdown-design-show"
                    : "dropdown-design dropdown-design-hidden"
                }
              >
                <div className="dropdown-design-content">
                  <span
                    onClick={() => {
                      setUnit("kg");
                      setDropdownHidden1(!dropdownHidden1);
                    }}
                  >
                    kg
                  </span>
                  <span
                    onClick={() => {
                      setUnit("lbs");
                      setDropdownHidden1(!dropdownHidden1);
                    }}
                  >
                    lbs
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="personal-details-next-button-center-alignment">
            {weight && feet && inch ? (
              <button onClick={() => handleOnboardingData()}>Submit</button>
            ) : (
              <button style={{ opacity: 0.5 }}>Submit</button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
