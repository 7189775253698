import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

import api from "../../../utils/api";
import { Loader } from "../../../utils/helpers";

import { ItemFromPayload, setItemToLocal, setToken } from "../../../utils";

import "./login.scss";

export default function Login(props) {
  const { forgotpassword, setFogotpassword } = props;
  const history = useHistory();
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    } else {
      setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    api
      .post("authentication/login", userInfo)
      .then((response) => {
        if (response?.data?.success) {
          if (response?.data?.payload?.user?.userLevel === 4) {
            const userInfo = response?.data?.payload;
            setToken(userInfo?.authenticationToken?.token);
            setItemToLocal("user", userInfo?.user);
            setItemToLocal("isQuestioned", userInfo?.user?.isQuestioned);
            setItemToLocal("isWeightUpdated", userInfo?.user?.isWeightUpdated);
            toast.success("Login successfull");
            setLoading(false);
            history.push("/");
          } else {
            setLoading(false);
            toast.error("Unauthorized user");
          }
        } else {
          setLoading(false);
          toast.error(response?.data?.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      });
  };

  const [passwordshown, setPasswordshown] = useState(false);
  const togglepasswordshoen = () => {
    setPasswordshown(passwordshown ? false : true);
  };

  return (
    <>
      {Loader(loading)}
      <div className="login-form-alignment">
        <h1>Welcome Back!</h1>
        <div className="form-details-alignment">
          <div className="input-alignment">
            <input type="text" name="email" placeholder="Email Address" value={userInfo.email} onKeyDown={handleChange} onChange={handleChange} />
          </div>
          <div className="input-alignment">
            {passwordshown ? (
              <i onClick={togglepasswordshoen} class="fa-solid fa-eye"></i>
            ) : (
              <i onClick={togglepasswordshoen} class="fa-solid fa-eye-slash"></i>
            )}
            <input
              type={passwordshown ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={userInfo.password}
              onKeyDown={handleChange}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="forgot-password-alignment" onClick={() => setFogotpassword(!forgotpassword)}>
          <span>Forgot password?</span>
        </div>
        <div className="login-button-alignment">
          {userInfo.password && userInfo.email ? (
            <button onClick={handleSubmit}>Log in </button>
          ) : (
            <button style={{ opacity: "0.5" }}>Log in </button>
          )}
        </div>
        <div className="signup-link-alignment">
          <p>
            Don’t have an account?
            <NavLink to="/signUp">
              <span>Sign up</span>
            </NavLink>
          </p>
        </div>
      </div>
    </>
  );
}
