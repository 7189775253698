import React, { useEffect } from 'react'
import DummyImage from "../../assets/images/dummy.png";
import './strengthPage.scss';
export default function StrengthPage() {
  useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    })
}, [])
  return (
    <div>
         <div className="strength-page-conditions-all-content-alignment">
        <div className="container">
          <div className="terms-conditions-banner">
            <h1>Strength Gains</h1>
          </div>
        </div>
        <div className="first-section-content-alignment">
          <div className="container-md-5">
            <p>PLEASE READ THESE TERMS & CONDITIONS CAREFULLY.</p>
            <p>
              These Terms & Conditions cover important information about the
              Centr Apps, the Website, Program and the Subscription, and any
              applicable fees.
            </p>
            <p>
              Your Subscription is governed by these Terms & Conditions as well
              as our Billing Terms.
            </p>
          </div>
        </div>
        <div className="repeat-text">
          <div className="container-md-5">
            <h4>Definitions</h4>
            <p>
              For the purposes of these terms and conditions the following
              definitions apply
            </p>
            <ol>
              <li>
                1. Affiliates means any of our associated entities as that term
                is defined in the Corporations Act 2001 (Cth);
              </li>
              <li>
                App means the Centr App owned and operated by us allowing access
                to the Website from remote devices, e.g. mobile phones and
                tablets;
              </li>

              <li>
                Facebook Community means the Facebook Community where registered
                members are able to communicate together be it on the Website or
                other third party platforms including but not limited to
                Facebook;
              </li>
              <li>
                Licence means the licence granted by us to you governing your
                use of the Website and the Services in accordance with these
                terms and conditions;
              </li>
              <li>One gender includes any other gender;</li>
              <li>
                Our, ourselves, us, we, refer to CH Digital Pty Ltd ACN 628 240
                497 trading as “CENTR” (“Centr”) and affiliates who for the
                purposes of these terms and conditions refer to any person or
                entity we directly or indirectly own, control or operate,
                currently or in the future and/or which has a controlling
                interest in us and/or is an entity who has the right to operate
                with or on behalf of ourselves;
              </li>

              <li>
                Party refers to a party to these terms and conditions and it
                includes that party's successors, administrators and assignors
                and where a party consists of more than person, then these terms
                and conditions bind them jointly and each of them severally;
              </li>
              <li>
                Program means the Centr program offered to you via the
                Website/App;
              </li>
              <li>
                Subscription means the subscription with Centr entitling you to
                use of the Website/App, the Program and/or the Facebook
                Community;
              </li>
              <li>
                Terms and Conditions means these terms and conditions as amended
                from time to time;
              </li>
              <li>
                Website means collectively the website centr.com, Facebook
                Community, Program, products and/or services offered or provided
                by Centr including the Centr App;
              </li>
              <li>
                You or yours refers to you, the person accessing the website and
                agreeing to the terms and conditions of your use of the Website
                and its contents.
              </li>
              <p>
                Note in these Terms and Conditions, the single includes the
                plural and the plural includes the singular.
              </p>
            </ol>
          </div>
          <div className="container-md-7">
            <div className="image-style">
              <img src={DummyImage} alt="DummyImage" />
            </div>
          </div>
          <div className="container-md-5">
            <h4>Licence</h4>
            <p>
              13. By virtue of these Terms and Conditions, you are granted a
              non-exclusive, non-transferable, non-sublicensable Licence to
              personally access and use, for non-commercial purposes, the
              Website/App and the services offered on the Website/App
              (“Services”).
            </p>
            <p>
              14. This Licence shall continue until terminated in accordance
              with this Agreement.
            </p>
            <h4>Website/App</h4>
            <p>
              15. These Terms and Conditions govern your right to use the
              Website/App and your access to and use of the Program, the
              Facebook Community and/or any products or services acquired in
              relation to the Program and/or any links provided on the
              Website/App to other websites.
            </p>
            <p>
              16. In downloading any content from the Website/App to your
              computer, you do not receive any ownership rights to such content
              and by downloading any content you agree not to use the content
              for any unlawful purpose and you agree that your use of the
              Website/App is only for your personal use and not for any
              commercial or other use contrary to these Terms and Conditions and
              our legal rights in respect of the Website/App, the Program and/or
              the Facebook Community.
            </p>
            <p>
              17. You agree that if you download any content from the
              Website/App you will not reproduce, distribute in person, publish,
              republish, print, upload to any third party, post on any social
              media site or Facebook Community, or distribute or modify or
              otherwise deal with any content in the Website/Apps in whatever
              format, personally or otherwise, and/or provide it, or any part of
              it, to a third party that would otherwise infringe our
              intellectual property rights.
            </p>
            <p>
              18. You agree that in downloading any service or product from the
              Website/App, you will not rent, lease or lend it to a third party
              nor decompile, reverse engineer, modify or derive content from the
              Website/App and/or make it available over a network where it could
              be used by multiple devices at the same time.
            </p>
            <p>
              19. You agree that your use of the Website/App will not violate
              any laws without limitation, including those governing
              competition, advertising, consumer protection laws, privacy,
              obscenity, spamming, stalking, intellectual property rights and/or
              defamation in Australia and when accessed in another jurisdiction
              the laws of that jurisdiction and Australian law, so far as the
              laws are not inconsistent, in which case you agree to be bound by
              the laws for New South Wales, Australia.
            </p>
            <p>
              20. You agree that in using the Website/App, you will not post
              threatening, harassing, defamatory, obscene, offensive, hate mail
              or speech or facilitate others to commit such acts in whatever
              format.
            </p>
            <p>
              21. You agree that we may, and reserve the right to, remove any
              comments made by you about the Website/App, any of its content,
              its individual representatives, officers, directors, consultants
              and/or employees which we believe in our sole discretion to be
              damaging to Centr.
            </p>
            <p>
              22. The content on the Website/App, including without limitation,
              the text, software, scripts, graphics, photos, sounds, music,
              videos, interactive features and the like and the trademarks,
              service marks and logos contained therein, are owned by or
              licensed to us, subject to copyright and other intellectual
              property rights under law.
            </p>
          </div>
          <div className="container-md-7">
            <div className="image-style">
              <img src={DummyImage} alt="DummyImage" />
            </div>
          </div>
          <div className="container-md-5">
            <h4>Eligibility</h4>
            <p>
              26. You represent and warrant that you are at least 18 years old
              and will be responsible for the information provided when creating
              a Subscription. This program is not designed or recommended for
              anyone under 18 years old. If you are accessing and using the
              Website/App or subscribing for access to the Program or any of the
              Website/App services or products on behalf of a party who is not
              at least 18 years of age then you are representing that you are
              that party's legal guardian and you are responsible for that
              party's compliance with these Terms and Conditions. You will
              indemnify us for any losses or damage that we suffer as a
              consequence of the party who is less than 18 years of age failing
              to comply with these Terms and Conditions.
            </p>
            <p>
              27. Without limitation, the Website/App is available only to
              individuals with whom only legally binding contracts can be formed
              under Australian law.
            </p>
            <p>
              28. We have the right to refuse to deal with you, at any time, at
              our sole discretion, including the suspension or termination of
              your Subscription, if we believe that you may or will bring our
              reputation, those individuals who represent us in the marketplace,
              our other users, and Facebook Community members into disrepute or
              otherwise will interfere with other parties' rights to have
              reasonable use of and access to the Website/App or to the contents
              and components of the Website/App or are in any way in breach of
              these Terms and Conditions.
            </p>
            <h4>Website/App Membership</h4>
            <p>
              29. You agree to provide accurate and truthful details about
              yourself for the purposes of your Subscription, for the Program
              and/or inclusion in the Facebook Community and we reserve the
              right to suspend or terminate your Subscription if we discover you
              have, at any time, provided inaccurate, incomplete or misleading
              personal information.
            </p>
            <p>
              30. Once you register on the Website/App, you will be given access
              to a "Clients only" email subscription and Facebook Community
              access.
            </p>
            <p>
              31. Access to the information provided through Centr subscription
              and the Facebook Community is for your sole use only.
            </p>
            <p>
              32. Any password or right given to you to obtain access to the
              "Clients only" email subscription and the Facebook Community, and
              the contents or use of either the email subscription or Facebook
              Community, is not transferable to any third party.
            </p>
            <p>
              33. We reserve the right, at our sole discretion, to terminate
              your access to the "Clients only" email subscription or Facebook
              Community if, in our opinion, you have failed to comply with any
              of the provisions of these Terms and Conditions.
            </p>
            <p>
              34. We reserve the right, at our sole discretion to terminate your
              access to the Facebook Community once you have cancelled your
              membership.
            </p>
            <h4>Website/App Membership</h4>
            <p>
              29. You agree to provide accurate and truthful details about
              yourself for the purposes of your Subscription, for the Program
              and/or inclusion in the Facebook Community and we reserve the
              right to suspend or terminate your Subscription if we discover you
              have, at any time, provided inaccurate, incomplete or misleading
              personal information.
            </p>
            <p>
              30. Once you register on the Website/App, you will be given access
              to a "Clients only" email subscription and Facebook Community
              access.
            </p>
            <p>
              31. Access to the information provided through Centr subscription
              and the Facebook Community is for your sole use only.
            </p>
            <p>
              32. Any password or right given to you to obtain access to the
              "Clients only" email subscription and the Facebook Community, and
              the contents or use of either the email subscription or Facebook
              Community, is not transferable to any third party.
            </p>
            <p>
              33. We reserve the right, at our sole discretion, to terminate
              your access to the "Clients only" email subscription or Facebook
              Community if, in our opinion, you have failed to comply with any
              of the provisions of these Terms and Conditions.
            </p>
            <p>
              34. We reserve the right, at our sole discretion to terminate your
              access to the Facebook Community once you have cancelled your
              membership.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

