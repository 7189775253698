import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import ChildCardImage from "../../assets/images/child-card.png";
import RecommendedPlan from "../workoutPage/recommendedPlan";
import DownloadFitApp from "../home/downloadFitApp";
import OpenLockIcon from "../../assets/icons/lock.svg";
import ClosedLockIcon from "../../assets/icons/lock-icon.svg";
import CartDetailsModal from "../myCart/myCartDetails/CartDetailsModal";
import api from "../../utils/api";
import {
  getItemFromLocal,
  ItemFromPayload,
  urlSearchParams,
} from "../../utils";
import {
  clientId,
  isSubscribed,
  isSubscriptionType,
  Loader,
} from "../../utils/helpers";

import "./workoutPlan.scss";
import ReactPlayer from "react-player";

export default function WorkoutPlan() {
  const history = useHistory();
  const planId = urlSearchParams("id");
  const userInfo = getItemFromLocal("user");
  const [loading, setLoading] = useState(false);
  const [selectedPlanWeekData, setSelectedPlanWeekData] = useState([]);
  const [recommendedPlans, setRecommendedPlans] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPlanDetails, setCurrentPlanDetails] = useState({});
  const [currentPlanVideo, setCurrentPlanVideo] = useState(
    "https://www.youtube.com/embed/J3jB4CS0x34"
  );
  const [planTittle, setPlanTittle] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [planId]);
  
  useEffect(() => {
    getWorkoutPlans();
  }, [])
  

  useEffect(() => {
    if (planId) getUserWorkoutPlans(planId);
  }, [planId]);

  const getUserWorkoutPlans = (id) => {
    setLoading(true);
    api
      .get(
        `plans/getUserWorkoutPlanDetails?PlanId=${id}&UserId=${
          userInfo?.userId || 0
        }&ClientId=${clientId()}`
      )
      .then((res) => {
        if (res.status === 200) {
          const a = [];
          const items = ItemFromPayload(res, "payload");
          items.weeks.map((z) =>
            z.days.map((y) => y.exercises.map((x) => a.push(x)))
          );
          setCurrentPlanDetails(items?.plans);
          setSelectedPlanWeekData(a);
          setCurrentPlanVideo(items?.plans?.promoUrl);
          setPlanTittle(items?.plans?.title);
          setLoading(false);
        }
      })
      .catch((err) => setLoading(false));
  };

  const getWorkoutPlans = () => {
    setLoading(true);
    api
      .get(
        `plans/getAppWorkoutPage?ClientId=${clientId()}&UserId=${
          userInfo?.userId || 0
        }`
      )
      .then((response) => {
        if (response.status === 200) {
          const item = ItemFromPayload(response, "payload");
          setRecommendedPlans(item.recommendedPlans);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div>
      {Loader(loading)}
      <div className="workout-plan-all-details-content-alignment">
        {selectedPlanWeekData?.length > 0 && (
          <div className="container">
            <div className="grid" >
              <div className="grid-items">
                {/* <iframe 
                  width="560"
                  height="315"
                  src={currentPlanVideo}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write;  gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe> */}
                <ReactPlayer
                  url={currentPlanVideo}
                  playing={true}
                  loop={true}
                  controls={true}
                  className="video-player"
                  width="100%"
                  height="555px"
                />

                        {/* <button className="subscribe-btn mt-10" onClick={() => history.push("/subscription-plans")} >
                            Subscribe Now 
                          </button> */}

{!currentPlanDetails?.isSubscribed && (
                    <div className="plan-access-button">
                      {isSubscriptionType() ? (
                        !isSubscribed() && (
                          <button className="subscribe-btn mt-10"
                            onClick={() => history.push("/subscription-plans")}
                          >
                            Subscribe Now 
                          </button>
                        )
                      ) : (
                        <button className="subscribe-btn mt-10" onClick={() => setIsOpen(true)}>Buy Now</button>
                      )}
                    </div>
                  )}

              </div>
              <div className="grid-items">
                <div className="light-box">
                  <h5
                    style={{
                      whiteSpace: "nowrap",
                      width: "400px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {planTittle} Details
                  </h5>
                  <div className="all-card-alignment">
                    <div>
                      {selectedPlanWeekData.map((plan) => {
                        return (
                          <div
                            className={`main-card ${
                              !currentPlanDetails?.isSubscribed &&
                              "locked-plan-videos"
                            }`}
                            onClick={() => setCurrentPlanVideo(plan.videoUrl)}
                          >
                            <div className="card-grid">
                              <div className="card-grid-items">
                                <div className="card-image">
                                  {plan?.imageUrl ? (
                                    <img
                                      src={
                                        plan?.imageUrl
                                          ? plan?.imageUrl
                                          : ChildCardImage
                                      }
                                      alt="ChildCardImage"
                                    />
                                  ) : (
                                    <ReactPlayer
                                      url={plan?.value}
                                      playing={true}
                                      loop={true}
                                      controls={true}
                                      width="960px"
                                      height="555px"
                                    />
                                    // <iframe src={plan?.value} title="YouTube video player" frameborder="0" allow="" allowfullscreen></iframe>
                                  )}
                                </div>
                              </div>
                              <div className="card-grid-items">
                                <div className="all-content-alignment">
                                  <div className="left-content">
                                    <h6>
                                      Week {plan?.week} - Day {plan?.day}
                                    </h6>
                                    <p>
                                      {plan.sets} Sets - {plan?.reps} Reps
                                    </p>
                                  </div>
                                  {!currentPlanDetails?.isSubscribed && (
                                    <div className="right-content">
                                      <img
                                        src={
                                          currentPlanDetails?.isSubscribed
                                            ? OpenLockIcon
                                            : ClosedLockIcon
                                        }
                                        alt="LockIcon"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* {!currentPlanDetails?.isSubscribed && (
                    <div className="plan-access-button">
                      {isSubscriptionType() ? (
                        !isSubscribed() && (
                          <button
                            onClick={() => history.push("/subscription-plans")}
                          >
                            Subscribe Now 
                          </button>
                        )
                      ) : (
                        <button onClick={() => setIsOpen(true)}>Buy Now</button>
                      )}
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <RecommendedPlan
        recommendedPlans={recommendedPlans}
        getWorkoutPlans={getWorkoutPlans}
      />
      <div className="workout-plan-top-bottom-section-alignment">
        <DownloadFitApp />
      </div>
      {isOpen && (
        <CartDetailsModal
          planDetails={currentPlanDetails}
          setIsOpen={setIsOpen}
          getUserWorkoutPlans={()=>getUserWorkoutPlans(planId)}

        />
      )}
    </div>
  );
}
