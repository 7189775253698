import React from "react";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";

import PlanCardImage from "../../../assets/images/plan-card-image.png";
import LeftArrow from "../../../assets/icons/plan-left-arrow.svg";

import "./lewinMorePlans.scss";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="right-arrow-content-alignment lewin-arrow-design" onClick={onClick}>
      {/* <img src={LeftArrow} alt="LeftArrow" /> */}

      <svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.63281 1.96387L1.63281 7.96387L7.63281 13.9639"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="left-arrow-content-alignment lewin-arrow-design" onClick={onClick}>
      {/* <img src={LeftArrow} alt="LeftArrow" /> */}
      <svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.17187 1.13574L1.17187 7.13574L7.17188 13.1357"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default function LewinMorePlans({ currentTrainer }) {
  const history = useHistory();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          nextArrow: false,
          prevArrow: false,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <div>
      <div className="lewinmore-plans-all-content-alignment">
        <div className="container">
          <div className="page-title">
            <h1>{currentTrainer?.name}'s More Plans</h1>
            <p>Set your goals high, and let’s get to work turning them into reality!</p>
          </div>
        
        <div className="lewin-more-plan-card-left-content-alignment">
          {currentTrainer?.plans?.length <= 4 ? (
            <div className="slidercard-alignment">
              {currentTrainer?.plans?.map((item) => {
                return (
                  <div onClick={() => history.push(`plan-details?id=${item.planID}`)}>
                    <div className="main-plan-card">
                      <div className="plan-card-image">
                        <img src={item?.bannerUrl || PlanCardImage} alt="PlanCardImage" />
                        <div className="blur-div"></div>
                        <div className="plan-card-details">
                          <div className="plan-card-details-alignment">
                            <h1>{item?.title}</h1>
                            <p>{item?.weeks} Weeks </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Slider {...settings}>
              {currentTrainer?.plans?.map((item) => {
                return (
                  <div onClick={() => history.push(`plan-details?id=${item.planID}`)}>
                    <div className="main-plan-card">
                      <div className="plan-card-image">
                        <img src={item?.bannerUrl || PlanCardImage} alt="PlanCardImage" />
                        <div className="blur-div"></div>
                        <div className="plan-card-details">
                          <div className="plan-card-details-alignment">
                            <h1>{item?.title}</h1>
                            <p>{item?.weeks} Weeks </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
        </div>
      </div>
    </div>
  );
}
