import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";

import WorkOutIcon from "../../assets/icons/workout.svg";
import EditIcon from "../../assets/icons/edit.svg";
import MealsIcon from "../../assets/icons/meals.svg";
import WeeksIcon from "../../assets/icons/weeks.svg";
import CameraIcon from "../../assets/icons/cameraIcon.svg";
import ProfileDetails from "./profileDetails";
import { Loader } from "../../utils/helpers";
import { getItemFromLocal, ItemFromPayload } from "../../utils";
import api from "../../utils/api";

import "./userProfile.scss";

export default function UserProfile() {
  const history = useHistory();
  const userInfo = getItemFromLocal("user");
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [userProfileQuesAns, setUserProfileQuesAns] = useState([]);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [editProfile, setEditProfile] = useState({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    const isQuestioned = getItemFromLocal("isQuestioned");
    const isWeightUpdated = getItemFromLocal("isWeightUpdated");
    if (!isQuestioned || !isWeightUpdated) history.push("/onboarding");
    getOnboardingQueAns();
  }, []);

  const getOnboardingQueAns = () => {
    setLoading(true);
    api
      .get(`authentication/GetAppUserProfile?UserID=${userInfo?.userId || 1}`)
      .then((res) => {
        console.log("resres", res?.data?.payload);
        if (res.status === 200) {
          const item = ItemFromPayload(res, "payload");
          setLoading(false);
          setUserProfile(item);
          setUserProfileQuesAns(item.questAns);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleOnProfileChange = (e) => {
    setLoading(true);
    let file = new FormData();
    file.append("image", e.target.files[0]);
    api
      .post(`authentication/UploadProfilePic?UserId=${userInfo?.userId}`, file)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data.error)
        setLoading(false);
      });
  };

  const handleOnchange = (e) => {
    setEditProfile({ ...editProfile, [e.target.name]: e.target.value,  });
  };

  // name profile
  const handleEditProfile = () => {
    setLoading(true);
    api
      .post("authentication/UpdateUserProfile", { ...userInfo, ...editProfile, height:userProfile.height, weight: userProfile.weight})
      .then((response) => {
        console.log("response", response);
        if (response.status === 200) {
          setLoading(false);
          toast.success("Profile updated successfully!");
          setIsEditProfile(false);
          setEditProfile({});
          getOnboardingQueAns();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.error)
        setLoading(false);
      });
  };

  return (
    <div>
      {Loader(loading)}
      <div className="user-profile-banner-content">
        <div className="">
          <div className="user-profile-banner">
            <div className="content-design">
              <div className="box-center-alignment">
                <div className="box">
                  <div className="icon-center-alignment">
                    <div className="icon-design">
                      <img src={WorkOutIcon} alt="WorkOutIcon" />
                    </div>
                  </div>
                  <p>{userProfile.workouts}</p>
                  <span>Workouts</span>
                </div>
                <div className="box">
                  <div className="icon-center-alignment">
                    <div className="icon-design">
                      <img src={MealsIcon} alt="WorkOutIcon" />
                    </div>
                  </div>
                  <p>{userProfile.meals}</p>
                  <span>Meals</span>
                </div>
                <div className="box">
                  <div className="icon-center-alignment">
                    <div className="icon-design">
                      <img src={WeeksIcon} alt="WorkOutIcon" />
                    </div>
                  </div>
                  <p>{userProfile.weeks}</p>
                  <span>Weeks</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {console.log('userProfile?.avtarURL********', userProfile?.avtarURL)} */}
        <div className="container">
          <div className="profile-image-content-left-right-alignment">
            <div className="profile-grid">
              <div className="profile-grid-items">
                <div className="profile-image">
                  {userProfile?.avtarURL ? (
                    <img
                      src={
                        // editProfile?.avtarURL
                        //   ? editProfile?.avtarURL
                        //   :
                        userProfile.avtarURL
                      }
                      alt="UserProfileImage"
                    />
                  ) : (
                    <p>
                      {userProfile?.name && userProfile?.name[0].toUpperCase()}
                    </p>
                  )}
                  {/* {isEditProfile && */}
                  <div className="icons-alignment">
                    <img src={CameraIcon} alt="CameraIcon" />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleOnProfileChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="profile-grid-items">
                <div className="all-content-alignment">
                  <div>
                    {isEditProfile ? (
                      <TextField
                        id="name"
                        name="name"
                        variant="standard"
                        placeholder="Name"
                        value={editProfile?.name}
                        onChange={(e) => handleOnchange(e)}
                      />
                    ) : (
                      <p>{userProfile?.name}</p>
                    )}
                    <span>{userProfile?.email}</span>
                  </div>
                  <div>
                    {isEditProfile ? (
                      <p className="edit-text" onClick={handleEditProfile}>
                        Save
                      </p>
                    ) : (
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        onClick={() => {
                          setIsEditProfile(true);
                          setEditProfile({
                            ...editProfile,
                            name: userProfile?.name,
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProfileDetails
        userProfileQuesAns={userProfileQuesAns}
        setUserProfileQuesAns={setUserProfileQuesAns}
        userProfile={userProfile}
        setUserProfile={setUserProfile}
        getOnboardingQueAns={getOnboardingQueAns}
      />
    </div>
  );
}
