import React, { useState } from "react";
import { toast } from "react-hot-toast";

import api from "../../../utils/api";
import { clientId, Loader } from "../../../utils/helpers";

import "./emailBox.scss";

export default function Emailbox() {
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [enquiryText, setEnquiryText] = useState("");

  const SendEnquiry = () => {
    if (!EMAIL_REGEX.test(email)) {
      toast.error("Enter valid email.");
    } else {
      setLoading(true);
      let EnquiryData = {
        id: 0,
        clientID: clientId(),
        userName: userName,
        email: email,
        enquiryText: enquiryText,
      };
      api
        .post(`clients/saveUserEnquiry`, EnquiryData)
        .then((response) => {
          toast.success("Request sent successfully!");
          setUserName("");
          setEmail("");
          setEnquiryText("");
          setLoading(false);
        })
        .catch((error) => {
          toast.error(error.response?.data?.error)
          setLoading(false);
        });
    }
  };

  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <div className="container">
      <div className="email-box-content-alignment">
        <div className="two-col-grid">
          <div className="two-col-grid-items">
            <div className="child-col">
              <div className="child-col-items">
                <input
                  type="text"
                  value={userName}
                  placeholder="Full Name"
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
              <div className="child-col-items">
                <input
                  type="text"
                  value={email}
                  placeholder="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="child-cols">
              <div className="child-col-items">
                <input
                  type="text"
                  value={enquiryText}
                  placeholder="Description"
                  onChange={(e) => setEnquiryText(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="two-col-grid-items">
            {userName && email && enquiryText ? (
              <button onClick={() => SendEnquiry()}>
                {" "}
                {Loader(loading)}Send Enquiry
              </button>
            ) : (
              <button style={{ opacity: 0.5 }}>Send Enquiry</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
