import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { toast } from "react-hot-toast";

import EditIcon from "../../../assets/icons/edit-gray.svg";
import ProfileIcon from "../../../assets/icons/user-profile.svg";
import LockIcon from "../../../assets/icons/lock1.svg";
import EyeIcon from "../../../assets/icons/eye-new.svg";
import EmailIcon from "../../../assets/icons/new-email.svg";
import EnableIcon from "../../../assets/icons/Enable.svg";
import DemoImg from "../../../assets/images/feature-plan.png";
import api from "../../../utils/api";
import { getItemFromLocal, ItemFromPayload } from "../../../utils";
import {
  clientId,
  currencySign,
  isSubscriptionType,
  Loader,
} from "../../../utils/helpers";

import "./accountDetails.scss";

export default function AccountDetails({
  userProfile,
  setUserProfile,
  userProfileQuesAns,
  setUserProfileQuesAns,
  getOnboardingQueAns,
}) {
  console.log("userProfile", userProfile);
  const userInfo = getItemFromLocal("user");
  const [loading, setLoading] = useState(false);
  const [isEditQuesAns, setIsEditQuesAns] = useState(false);
  const [subscribedPlans, setSubscribedPlans] = useState([]);
  const [purchasingPlans, setPurchasingPlans] = useState([]);

  useEffect(() => {
    getPurchasingPlans();
  }, []);

  const getPurchasingPlans = () => {
    setLoading(true);
    api
      .get(
        `userSubscription/GetUserActiveSubscription?UserId=${
          userInfo?.userId
        }&ClientId=${clientId()}`
      )
      .then((res) => {
        if (res.status === 200) {
          if(isSubscriptionType() ){
            setSubscribedPlans(ItemFromPayload(res, "payload"));
            setLoading(false);
          }else{
          setPurchasingPlans(ItemFromPayload(res, "payload"));
          setLoading(false);
          }
        }
      })
      .catch((err) => setLoading(false));
  };

  const handleOnSaveUserInfo = () => {
    setLoading(true);
    api
      .post("authentication/SaveUserOnboardingAnswers", userProfileQuesAns)
      .then((res) => {
        if (res.status === 200) {
          api
            .post("authentication/UpdateUserWeightHeight", {
              userID: userProfile.userId,
              weight: userProfile.weight,
              height: userProfile.height,
            })
            .then((res) => {
              if (res.status === 200) {
                setLoading(false);
                toast.success("Profile updated successfully!");
                setIsEditQuesAns(false);
              }
            })
            .catch((error) => {
              setLoading(false);
              toast.error(error.response?.data?.error);
            });
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      });
  };

  const handleOnQuesAnsChange = (ev) => {
    setUserProfileQuesAns(
      userProfileQuesAns.map((item) => ({
        ...item,
        clientId: clientId(),
        userId: userInfo?.userId,
        value: item.questionId == ev.target.id ? ev.target.value : item.value,
      }))
    );
  };

  const handleOnAppearanceChange = (ev) => {
    setUserProfile({ ...userProfile, [ev.target.name]: ev.target.value });
  };


  const handleOnEmailPermission = (e) => {
    setLoading(true);
    api
      .post("authentication/UpdateUserProfile", {
        ...userInfo,
        emailsPermission: e.target.checked,
      })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          getOnboardingQueAns();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.error);
        setLoading(false);
      });
  };

  return (
    <div>
      {Loader(loading)}
      <div className="user-profile-details-account-details">
        <div className="account-details-box">
          <div className="account-details-header">
            <p>Account Details</p>
          </div>
          <div className="account-details-body-content-alignment">
            {userProfileQuesAns?.length > 0 &&
              userProfileQuesAns?.map((item, index) => (
                <>
                  <div className="first-goal-content-alignment">
                    <p>{item.question}</p>
                    {index === 0 &&
                      (isEditQuesAns ? (
                        <p className="edit-text" onClick={handleOnSaveUserInfo}>
                          Save
                        </p>
                      ) : (
                        <img
                          src={EditIcon}
                          alt="EditIcon"
                          // className="edit-text"
                          style={{ width: "22px", cursor: "pointer" }}
                          onClick={() => setIsEditQuesAns(true)}
                        />
                      ))}
                  </div>
                  <div className="build-text">
                    {isEditQuesAns ? (
                      <TextField
                        id={item.questionId}
                        variant="standard"
                        value={item.value}
                        onChange={handleOnQuesAnsChange}
                      />
                    ) : (
                      <span>{item.value}</span>
                    )}
                  </div>
                </>
              ))}
            <div className="button-text-alignment">
              <span>Height</span>
              {isEditQuesAns ? (
                <TextField
                  id="height"
                  name="height"
                  variant="standard"
                  value={userProfile?.height}
                  onChange={handleOnAppearanceChange}
                />
              ) : (
                <button>{userProfile?.height}</button>
              )}
            </div>
            <div className="button-text-alignment">
              <span>Weight</span>
              {isEditQuesAns ? (
                <TextField
                  id="weight"
                  name="weight"
                  variant="standard"
                  value={userProfile?.weight}
                  onChange={handleOnAppearanceChange}
                />
              ) : (
                <button>{userProfile?.weight}</button>
              )}
            </div>
            {/* <div className="input-box-content-design">
              <div className="box-input">
                <input type="text" placeholder="User Name" />
                <div className="icon-right-content-alignment">
                  <img src={ProfileIcon} alt="ProfileIcon" />
                </div>
              </div>
              <div className="box-input">
                <input type="text" placeholder="hello@dotpixelagency.com" />
                <div className="icon-right-content-alignment">
                  <img src={LockIcon} alt="LockIcon" />
                </div>
              </div>
              <div className="box-input">
                <input type="text" placeholder="********" />
                <div className="icon-right-content-alignment">
                  <img src={EyeIcon} alt="EyeIcon" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {isSubscriptionType() ? (
          <div className="my-subscription-plan">
            {subscribedPlans?.length > 0 && (
              <>
                <div className="my-subscription-header">
                  <p>My Subscription Plans</p>
                </div>
                {subscribedPlans?.map((obj) => {
                  console.log("obj", obj);
                  return (
                    <div className="monthly-box-content-alignment">
                      <div className="monthly-box">
                        <p>
                          {currencySign()}
                          {obj?.finalPrice}/{obj?.planType}
                        </p>
                        <span>{obj?.planDesc}</span>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        ) : (
          <div className="my-pruchase-plan">
            {purchasingPlans?.length > 0 && (
              <>
                <div className="my-pruchase-header">
                  <p>My Purchased Plans</p>
                </div>
                <div className="monthly-box-content-alignment">
                  <div className="purchase-grid">
                    {purchasingPlans?.map((item, index) => {
                      return (
                        <div className="purchase-grid-item" key={index}>
                          <div className="purchase-box">
                            <div className="plan-img">
                              <img src={item?.bannerUrl} alt="DemoImg" />
                            </div>
                            <div className="purchase-plan-details">
                              <h4>{item?.planName}</h4>
                              <p>
                                {currencySign()}
                                {item?.finalPrice}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {/* <div className="social-content">
          <div className="social-header">
            <p>Social Connect</p>
          </div>
          <div className="social-body">
            <div className="text-grid">
              <div className="text-grid-items">
                <p>Facebook</p>
              </div>
              <div className="text-grid-items">
                <span>Connected</span>
              </div>
            </div>
            <div className="text-grid">
              <div className="text-grid-items">
                <p>Gmail</p>
              </div>
              <div className="text-grid-items">
                <span>Connected</span>
              </div>
            </div>
          </div>
        </div> */}
        <div className="premission-content">
          <div className="premission-header">
            <p>Permissions</p>
          </div>
          <div className="premission-body">
            <div className="all-text-alignment">
              <div className="left-content toggle-switch">
                <img src={EmailIcon} alt="EmailIcon" />

                <div>
                  <p>Emails</p>
                  <span>Send me updates from varun's team</span>
                </div>
              </div>
              <div>
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={userProfile?.emailsPermission}
                    onChange={(e) => handleOnEmailPermission(e)}
                  />
                  <span className="slider round"></span>
                </label>
                {/* <img src={EnableIcon} alt="EnableIcon" /> */}
              </div>
            </div>
            {/* <div className="all-text-alignment">
              <div className="left-content">
                <img src={EmailIcon} alt="EmailIcon" />
                <div>
                  <p>Google Fit</p>
                  <span>Send me uploadtes from Chris the team</span>
                </div>
              </div>
              <div>
                <img src={EnableIcon} alt="EnableIcon" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
