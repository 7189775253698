import React, { useEffect, useState } from "react";

import api from "../../utils/api";
import { clientId, Loader } from "../../utils/helpers";
import { ItemFromPayload, urlSearchParams } from "../../utils";

import "./faq.scss";

export default function Faq() {
  const [allFaqData, setAllFaqData] = useState([]);
  const [loading, setLoading] = useState(false);
  const theme = urlSearchParams("theme");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getAllFaqData();
  }, []);

  const getAllFaqData = () => {
    setLoading(true);
    api
      .get(`clients/getAppSetting?ClientId=${clientId()}`)
      .then((response) => {
        if (response.status === 200) {
          let payloadData = ItemFromPayload(response, "payload");
          let filterData = payloadData?.filter((item) => item?.keyID === 5);
          setAllFaqData(filterData[0]?.value);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {Loader(loading)}
      <div className="faq-conditions-all-content-alignment" style={{ backgroundColor: theme == "black" ? "#000000" : "none" }}>

        <div className="container">
          <div className="terms-conditions-banner">
            <h1>License</h1>
          </div>
        </div>

        <div className="first-section-content-alignment">
          <div className="container-md-5">
            <p dangerouslySetInnerHTML={{ __html: allFaqData }} style={{color:theme == "black" ? "#FFFFFF":"#5E5E5E"}} />
          </div>
        </div>
      </div>
    </div>
  );
}
