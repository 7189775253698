import React from 'react'
import './downloadFitApp.scss';
import GooglePayIcon from '../../../assets/icons/google-pay.svg';
import HomeMobile from '../../../assets/icons/Home.svg';
import AppStoreIcon from '../../../assets/icons/app-store.svg';
import SplashIcon from '../../../assets/icons/Splash.svg';
export default function DownloadFitApp() {
  return (
    <div>
        <div className='download-fit-all-content-alignment'>
          
                <div className='fit-app'>
                <div className='container'>
                    <div className='grid'>
                        <div className='grid-items'>
                            <h2>Download Fitapp</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur malesuada 
                                dui eget ex commodo, et mattis magna sollicitudin. Duis est leo, tempus sed pretium in
                            </p>
                            <div className='icon-center-alignment'>
                                <img src={GooglePayIcon} alt="GooglePayIcon"/>
                            <img src={AppStoreIcon} alt="AppStoreIcon"/>
                            </div>
                        </div>
                        <div className='grid-items'>
                            <div className='home-mobile'>
                                <img src={HomeMobile} alt="HomeMobile"/>
                            </div>
                            <div className='sec-mobile'>
                                <img src={SplashIcon} alt="SplashIcon"/>
                            </div>
                        </div>
                    </div>
                 </div>
                </div>
            
        </div>
    </div>
  )
}
