import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import MainBlog from "../../assets/images/main-blog.png";
import BlogCard from "./blogCard";
import api from "../../utils/api";
import { getItemFromLocal, ItemFromPayload } from "../../utils";
import { clientId, Loader } from "../../utils/helpers";

import "./blog.scss";

export default function Blog() {
  const history = useHistory();
  const userInfo = getItemFromLocal("user");
  const [loading, setLoading] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getAllBlogPosts();
  }, []);

  const getAllBlogPosts = () => {
    setLoading(true);
    api
      .get(`blogs/GetBlogPosts?ClientID=${clientId()}&UserID=${userInfo?.userId || 18}`)
      .then((response) => {
        if (response.status === 200) {
          setBlogPosts(ItemFromPayload(response, "payload"));
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {Loader(loading)}
      <div className="first-main-two-blog-alignment">
        <div className="container">
          <div className="grid">
            {blogPosts?.slice(0, 2).map((blog) => (
              <div className="grid-items" onClick={() => history.push(`/blog-details?id=${blog.postID}`)}>
                <div className="card-image">
                  <img src={blog.uploads.length > 0 ? blog.uploads[0].uploadUrl : MainBlog} alt="MainBlog" />
                </div>
                <div className="blur-div"></div>
                <div className="card-details">
                  <div className="card-details-alignment">
                    <h5>{blog.title}</h5>
                    <p>{blog.bodyText}</p>
                    {/* <span>Learn More</span> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <BlogCard blogPosts={blogPosts} />
    </div>
  );
}
