import React from 'react'
import './paymentTypeModal.scss';
import LgAllStoreIcon from '../../assets/icons/lg-app-store.svg';
import ScanIcon from '../../assets/icons/scan.svg';
export default function PaymentTypeModal() {
  return (
    <div>
        <div className='payment-type-modal-wrapper'>
            <div className='payment-type-modal-md'>
                <div className='grid'>
                    <div className='grid-items'>
                        <div className='icon-alignment'>
                            <img src={LgAllStoreIcon} alt="LgAllStoreIcon"/>
                        </div>
                        <div className='scan-box'>
                            <img src={ScanIcon} alt="ScanIcon"/>
                        </div>
                    </div>
                    <div className='grid-items'>
                        <div className='icon-alignment'>
                            <img src={LgAllStoreIcon} alt="LgAllStoreIcon"/>
                        </div>
                        <div className='scan-box'>
                            <img src={ScanIcon} alt="ScanIcon"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
