import React from "react";

// import FitappsTeamStep from "./fitappsTeamStep";
import TeamImage from "../../../assets/images/team-image.png";
import SecTeamImage from "../../../assets/images/sec-team.png";
import ThreeTeamImage from "../../../assets/images/three-team.svg";

import HealthiconsIcon from '../../../assets/icons/healthicons_gym-negative.svg';
import StepTwoIcon from '../../../assets/icons/step-two.svg';
import VideoIcon from '../../../assets/icons/video.svg';

import "./fitappsTeam.scss";

export default function FitappsTeam() {

  // const scrollDown = (ref) => {
  //   window.scrollTo({
  //     top: ref.current.offsetTop,
  //     behavior: 'smooth',
  //   });
  // };


  return (
    <div>



      <div className="fitapps-team-all-content-alignment">

        <div className="container">
        <div className="page-title">
                    <h1>Train, Eat, Live Better with Fitapp’s Team</h1>
                    <p>Find the Perfect Short Term Training Program for your goals </p>
                  </div>

                 
        </div>

        <div className="container-lg">
          <div className="main-grid">
            {/* <div className="main-grid-items">
              <FitappsTeamStep />
            </div> */}
            <div className="main-grid-items">
              <div className="first-grid">
                <div className="first-grid-items">
                  <div className="same-content-style">

                  <div className='lg-round-bottom-alignment right-mg' >
                      <div className='lg-round'>
                          <img src={HealthiconsIcon} alt="HealthiconsIcon"/>
                      </div>
                      <div className='text-style'>
                          <span>Workout</span>
                      </div>
                  </div>

              <div>

                    <h2>Workout plans tailorred to your goals</h2>
                    <p>World Class Trainers at your Fingertrips.</p>
                    <div className="button-alignment">
                      <button>Lose weight</button>
                      <button>Get fit & toneds</button>
                      <button>Build Muscle</button>
                    </div>
              </div>

                  </div>
                </div>
                <div className="first-grid-items">
                  <div className="team-image">
                    <img src={TeamImage} alt="TeamImage" />
                  </div>
                </div>
              </div>

            

              <div className="sec-grid" >
                <div className="sec-grid-items">
                  <div className="team-image">
                    <img src={SecTeamImage} alt="SecTeamImage" />
                  </div>
                </div>
                <div className="sec-grid-items">
                  <div className="same-content-style">

                  <div className='lg-round-bottom-alignment left-mg mobile-img' >
                <div className='lg-round'>
                    <img src={StepTwoIcon} alt="StepTwoIcon"/>
                </div>
                <div className='text-style'>
                    <span>Healthy Food</span>
                </div>
            </div>
                    
                    <div>
                    <h2>Easy healthy food that you’ll actually love</h2>
                    <p>
                      Dietician-approved meal plans to suit yourfitness goal.
                    </p>
                    <div className="button-alignment">
                      <button>Regular</button>
                      <button>Pechcatarian</button>
                      <button>Vege tarian </button>
                      <button>Vegan</button>
                    </div>
                    </div>
                    <div className='lg-round-bottom-alignment left-mg decstop-img' >
                <div className='lg-round'>
                    <img src={StepTwoIcon} alt="StepTwoIcon"/>
                </div>
                <div className='text-style'>
                    <span>Healthy Food</span>
                </div>
            </div>
                  </div>

                 
                </div>
              </div>
              <div className="first-grid pb-0" >
                <div className="first-grid-items">
              
                  <div className="same-content-style">
                  <div className='lg-round-bottom-alignment right-mg' >
                <div className='lg-round'>
                    <img src={VideoIcon} alt="VideoIcon"/>
                </div>

                <div className='text-style'>
                    <span>Find App</span>
                </div>
            </div>

            <div>
                    <h2>Find your fitapp and calm the chaos</h2>
                    <p>
                      Tools, tricks advice and 24/7 community support at your
                      fingertips
                    </p>
                    <div className="button-alignment">
                      <button>Meditate</button>
                      <button>Sleep</button>
                      <button>Tips</button>
                    </div>

            </div>
                  </div>
                </div>
                <div className="first-grid-items">
                  <div className="team-image">
                    <img src={ThreeTeamImage} alt="ThreeTeamImage" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
