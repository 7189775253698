import React from 'react'
import './fitappLogo.scss';
import Logo from '../../assets/icons/logo.svg';
import { NavLink } from 'react-router-dom';
export default function FitappLogo() {
  return (
    <div>
        <div className='fit-app-logo'>
          <NavLink to="/">
            <img src={Logo} alt="Logo"/>
            </NavLink>
        </div>
    </div>
  )
}
