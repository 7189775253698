import React from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";

import RecommendedImage from "../../../assets/images/meal.png";
import { currencySign, isSubscriptionType } from "../../../utils/helpers";

import "./recommendedMealPlan.scss";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="right-arrow-alignment same-arrow-box" onClick={onClick}>
      <svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.17187 1.13574L1.17187 7.13574L7.17188 13.1357"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="left-arrow-alignment same-arrow-box" onClick={onClick}>
      <svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.17187 1.13574L1.17187 7.13574L7.17188 13.1357"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default function RecommendedMealPlan({ recommendedPlans }) {
  const history = useHistory();
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    // centerMode: true,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          nextArrow: false,
          prevArrow: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          nextArrow: false,
          prevArrow: false,
        },
      },
      
    ],
  };

  return (
    <div>
      <div className="recommended-meal-plan-all-content-alignment">
        <div className="container">
          <div className="page-title">
            <p>Recommended Meal Plans</p>
          </div>
        
        <div className="recommended-plan-left-content">
          {recommendedPlans?.length <= 3 ? (
            <div className="slidercard-alignment">
              {recommendedPlans?.map((plan) => {
                return (
                  <div
                    onClick={() =>
                      history.push(`/meal-plan-details?id=${plan?.planID}`)
                    }
                  >
                    <div className="main-card" style={{ width: "390px" }}>
                      <div className="card-image">
                        <div className="card-blur"></div>
                        <img
                          src={plan?.bannerUrl2 || RecommendedImage}
                          alt="RecommendedImage"
                        />
                        <div className="card-details">
                          <div className="card-details-alignment">
                            <div className="left-side-alignment">
                              <h4>{plan?.title}</h4>
                              <p>{plan?.trainerName}</p>
                            </div>
                            {!isSubscriptionType() && (
                              <div className="right-side-alignment">
                                {plan?.discount > 0 ? (
                                  <p>
                                    {currencySign()}
                                    {plan?.finalPrice}
                                    &nbsp;&nbsp;
                                    <s style={{ color: "#fcf2f2" }}>
                                      {currencySign()}
                                      {plan?.basePrice}
                                    </s>
                                    &nbsp;
                                    {/* ({plan?.discount}% OFF) */}
                                  </p>
                                ) : (
                                  <p>
                                    {currencySign()}
                                    {plan?.basePrice}
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Slider {...settings}>
              {recommendedPlans?.map((plan) => {
                return (
                  <div
                    onClick={() =>
                      history.push(`/meal-plan-details?id=${plan?.planID}`)
                    }
                   
                  >

                    <div className="main-card">
                      <div className="card-image">
                        <div className="card-blur"></div>
                        <img
                          src={plan?.bannerUrl2 || RecommendedImage}
                          alt="RecommendedImage"
                        />
                        <div className="card-details">
                          <div className="card-details-alignment">
                            <div className="left-side-alignment">
                              <h4>{plan?.title}</h4>
                              <p>{plan?.trainerName}</p>
                            </div>
                            {!isSubscriptionType() && (
                              <div className="right-side-alignment">
                                {plan?.discount > 0 ? (
                                  <p>
                                    {currencySign()}
                                    {plan?.finalPrice}
                                    &nbsp;&nbsp;
                                    <s style={{ color: "#fcf2f2" }}>
                                      {currencySign()}
                                      {plan?.basePrice}
                                    </s>
                                    &nbsp;
                                    {/* ({plan?.discount}% OFF) */}
                                  </p>
                                ) : (
                                  <p>
                                    {currencySign()}
                                    {plan?.basePrice}
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
        </div>
      </div>
    </div>
  );
}
