import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Slider from "react-slick";

import RecommendedImage from "../../../assets/images/recommended.png";
import { currencySign, isSubscriptionType } from "../../../utils/helpers";
import CartDetailsModal from "../../myCart/myCartDetails/CartDetailsModal";

import "./recommendedPlan.scss";

function SampleNextArrow({ onClick }) {
  return (
    <div className="right-arrow-alignment same-arrow-box" onClick={onClick}>
      <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.17187 1.13574L1.17187 7.13574L7.17188 13.1357" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  );
}

function SamplePrevArrow({ onClick }) {
  return (
    <div className="left-arrow-alignment same-arrow-box" onClick={onClick}>
      <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.17187 1.13574L1.17187 7.13574L7.17188 13.1357" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  );
}

export default function RecommendedPlan({ handleAddToCart, recommendedPlans, showButtons ,getWorkoutPlans}) {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [planDetails, setPlanDetails] = useState({});

  const handlePurchasePlan = (e, item) => {
    e.stopPropagation();
    setIsOpen(true);
    setPlanDetails(item);
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    // centerMode: true,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [

      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <div>
      <div className="recommended-plan-all-content-alignment">
        <div className="container">
          <div className="page-title">
            {showButtons ? (
              <p>Our Recommended Plans</p>
              ):(
              <p>Recommended Plans</p>
            )}
          </div>
        
        <div className="recommended-plan-left-content">
          {recommendedPlans?.length <= 3 ? (
            <div className="slidercard-alignment">
              {recommendedPlans?.map((item) => {
                return (
                  <NavLink to={`/workout-plan-details?id=${item?.planID}`}>
                    <div
                    // onClick={() => history.push(`/workout-plan-details?id=${item?.planID}`)}
                    >
                      <div className="main-card" style={{ width: "390px" }}>
                        <div className="card-image">
                          <img src={item?.bannerUrl2 || RecommendedImage} alt="RecommendedImage" />
                          <div className="card-details">
                            <div className="card-details-alignment">
                              <h4>{item?.title}</h4>
                              {!showButtons &&
                              <p>{item?.trainerName}</p>
                              }
                              
                              {item?.discount > 0 ? (
                                <h6>
                                  {currencySign()}
                                  {item?.finalPrice}
                                  &nbsp;&nbsp;
                                  <s style={{ color: "#7e818c" }}>
                                    {currencySign()}
                                    {item?.basePrice}
                                  </s>
                                  &nbsp;
                                  {/* ({item?.discount}% OFF) */}
                                </h6>
                              ) : (
                                <h6>
                                  {currencySign()}
                                  {item?.basePrice}
                                </h6>
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        {showButtons && item?.isSubscribed ? (
                          <div className="purchase-button-alignment">
                            <button>purchased</button>
                          </div>
                        ) : (
                          <>
                            {showButtons && (
                              <div className="price-button-alignment">
                                <div className="price-alignment">
                                  {item?.discount > 0 ? (
                                    <h6>
                                      {currencySign()}
                                      {item?.finalPrice}
                                      &nbsp;&nbsp;
                                      <s style={{ color: "#7e818c" }}>
                                        {currencySign()}
                                        {item?.basePrice}
                                      </s>
                                      &nbsp;
                                      {/* ({item?.discount}% OFF) */}
                                    </h6>
                                  ) : (
                                    <h6>
                                      {currencySign()}
                                      {item?.basePrice}
                                    </h6>
                                  )}{" "}
                                  {/* <h6>{currencySign()}{item?.basePrice}</h6> */}
                                </div>
                                <div className="buy-cancle-button-alignment">
                                  <>
                                    <button onClick={(e) => handlePurchasePlan(e, item)}>Buy Now</button>
                                    <button onClick={(e) => handleAddToCart(e, item.planID)}>Add to cart</button>
                                  </>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </NavLink>
                );
              })}
            </div>
          ) : (
            <Slider {...settings}>
              {recommendedPlans?.length > 0 &&
                recommendedPlans?.map((item) => {
                  return (
                    <div
                    
                      // <NavLink to={`/workout-plan-details?id=${item?.planID}`}>
                    >
                        <div className="inner-page main-card">
                          <div className="card-image" onClick={() => history.push(`/workout-plan-details?id=${item?.planID}`)}>
                            <img src={item?.bannerUrl2 || RecommendedImage} alt="RecommendedImage" />
                            <div className="card-details">
                              <div className="card-details-alignment">
                                <h4 >{item?.title}</h4>
                                {!showButtons &&
                                <p>{item?.trainerName}</p>
                                }
                              </div>
                              {showButtons && item?.isSubscribed ? (
                                <div className="purchase-button-alignment">
                                  <button>purchased</button>
                                </div>
                              ) : (
                                <>
                                  {showButtons && (
                                    <div className="price-button-alignment">
                                      <div className="price-alignment">
                                        {item?.discount > 0 ? (
                                          <h6>
                                            {currencySign()}
                                            {item?.finalPrice}
                                            &nbsp;&nbsp;
                                            <s style={{ color: "#7e818c" }}>
                                              {currencySign()}
                                              {item?.basePrice}
                                            </s>
                                            &nbsp;
                                            {/* ({item?.discount}% OFF) */}
                                          </h6>
                                        ) : (
                                          <h6>
                                            {currencySign()}
                                            {item?.basePrice}
                                          </h6>
                                        )}{" "}
                                      </div>
                                      <div className="buy-cancle-button-alignment">
                                        <>
                                          <button onClick={(e) => handlePurchasePlan(e, item)}>Buy Now</button>
                                          <button onClick={(e) => handleAddToCart(e, item.planID)}>Add to cart</button>
                                        </>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* </NavLink> */}
                      </div>
                  );
                })}
            </Slider>
          )}
        </div>
        </div>
      </div>
      {isOpen && <CartDetailsModal planDetails={planDetails} setIsOpen={setIsOpen} getWorkoutPlans={getWorkoutPlans}/>}
    </div>
  );
}
