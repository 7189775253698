import React, { useEffect, useState } from "react";

import api from "../../utils/api";
import { clientId, Loader } from "../../utils/helpers";
import { ItemFromPayload, urlSearchParams } from "../../utils";

import "./privacyPolicy.scss";

export default function PrivacyPolicy() {
  const [privacyData, setPrivacyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const theme = urlSearchParams("theme");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getPrivacyData();
  }, []);

  const getPrivacyData = () => {
    setLoading(true);
    api
      .get(`clients/getAppSetting?ClientId=${clientId()}`)
      .then((response) => {
        if (response.status === 200) {
          let payloadData = ItemFromPayload(response, "payload");
          let filterData = payloadData?.filter((item) => item?.keyID === 3);
          setPrivacyData(filterData[0]?.value);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {Loader(loading)}
      <div
        className="privacy-policy-banner-content-alignment"
        style={{ backgroundColor: theme == "black" ? "#000000" : "none" }}
      >
        <div className="container">
          <div className="privacy-policy-banner">
            <h1>Fitapp Privacy Policy</h1>
          </div>
        </div>
        <div className="first-content-section-alignment">
          <div className="container-md-5">
            <p
              dangerouslySetInnerHTML={{ __html: privacyData }}
              style={{ color: theme == "black" ? "#FFFFFF" : "#5E5E5E" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
