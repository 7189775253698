import React, { useEffect } from 'react'
import './checkoutPage.scss';
import WrongImage from '../../assets/icons/wrong.svg';
export default function CheckoutPage() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])
  return (
    <div>
        <div className='checkout-page-all-content-alignment'>
            <div className='container'>
                <div className='white-box'>
                    <div className='checkout-box'>
                        <h2>Something went wrong</h2>
                        <div className='image-center-alignment'>
                            <img src={WrongImage} alt="WrongImage"/>
                        </div>
                        <div className='rounded-md-button'>
                            <button>Back to home</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
