import axios from "axios";
import Cookies from "js-cookie";

import { BASE_URL } from "../config";

export const getToken = (key = "access_token") =>
  sessionStorage.getItem(key) ||
  localStorage.getItem(key) ||
  Cookies.get("token") ||
  undefined;

function makeHeaders() {
  let headerObj = {};
  if (getToken("access_token")) {
    const token = `Bearer ${getToken("access_token")}`;
    headerObj = {
      Authorization: token,
    };
  } else {
    const token = `Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjcyMzg4NzZDRUNBRkVGMTRGQjNCMUIyODNCNjJFMDZDQ0M5NEMxM0MiLCJ0eXAiOiJKV1QifQ.eyJlbWFpbCI6InBpeXVzaEBkZW1vLmNvbSIsIm5iZiI6MTY3NDgzNDA5MywiZXhwIjoxNjc2NjM0MDkzLCJpYXQiOjE2NzQ4MzQwOTMsImlzcyI6InVybjpGaXROYXRpdmU6Rml0YXBwOmF1dGgtYXBpIiwiYXVkIjoidXJuOkZpdE5hdGl2ZTpGaXRhcHAifQ.FKsZeURAbRIasSPcZsPpNLEGkw-fmeT9S8OiQcEeJirZPnwauk2m262Wf3xjQlYcmv4L8LlDeIeggzEHSW3cNk8BWqfn51UvraxBcBykAKp55tw0MbapvGL19FcmzYFktL5BLipxPkKeTY4d7TSbq1gkhgiJv5CZSHZFT9ee-tX6DrCQIfbRxdoS0EAJxZ37RkVhY7ewiws_eF4mAztx6ikug57zu5Ze_Yb-Sh9H4m_lPUwYRIHNZ2c4cCGxMp6qNfk4yxiD_65it0a3X0kPXI9P2mP2M-qxHgYFMP-29qpR-wdsTPrF1LFoy_5qkOYat_1_IzXV5lXb1gvpDbUxtg`;
    headerObj = {
      Authorization: "",
    };
  }
  return headerObj;
}

const axiosApi = axios.create({
  baseURL: `${BASE_URL}/`,
});

axiosApi.interceptors.request.use((request) => {
  if (!request.ignoreAuth) {
    request.headers = makeHeaders();
  }
  return request;
});

// axiosApi.interceptors.response.use(
//   (response) => response,
//   (err) => {
//     const errorCode =
//       err.response && err.response.data && err.response.data.code;
//     const errorMessage =
//       err.response &&
//       err.response.data &&
//       err.response.data.message &&
//       err.response.data.message.email;
//     if (errorCode === 404 && errorMessage === "Account does not exist") {
//       window.location = "/?unAuthorisedUser=true";
//     } else if (err.response && err.response.status === 401) {
//       Cookies.remove("token");
//       localStorage.clear();
//       sessionStorage.clear();
//       window.location = "/login?message=token-expired";
//       return true;
//     }
//     if (err.response && err.response.status === 403) {
//       // showNotification(err.response.data.msg, 'error', 5000);
//     }
//     if (err.response && err.response.status === 500) {
//       // showNotification('Unknown server error', 'error', 5000);
//     }
//     return Promise.reject(err);
//   }
// );

export default axiosApi;
