import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FitappLogo from "../fitappLogo";
import RequestStates from "../../utils/request-states";
import { ReactComponent as BookmarkIcon } from "../../assets/icons/bookmark.svg";
import { ReactComponent as GymIcon } from "../../assets/icons/gym-icon.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user-outlined.svg";
import { ReactComponent as CartIcon } from "../../assets/icons/shopping-bag.svg";
import { clearLocal, getItemFromLocal } from "../../utils";
import { isSubscribed, isSubscriptionType } from "../../utils/helpers";
import { getCartItemCount } from "../../redux/reducer";
import { Loader } from "../../utils/helpers";
import { getToken } from "../../utils/api";

import "./header.scss";

export default function Header() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const [mobileHeader, setMobileHeader] = useState(false);
  const cartCount = useSelector((state) => state.webAppData.cartItemCount);
  const loading = useSelector(
    (state) => state.webAppData.loaderRequestState === RequestStates.loading
  );

  const headerMenuItems = [
    // {
    //   icon: <BookmarkIcon />,
    //   active: window.location.pathname === "/wishlist",
    //   pathname: "/wishlist",
    // },
    // {
    //   icon: <GymIcon />,
    //   active: window.location.pathname === "/plan-progress-details",
    //   pathname: "/plan-progress-details",
    // },
    {
      icon: <CartIcon />,
      active: window.location.pathname === "/my-cart",
      hidden: isSubscriptionType(),
      pathname: "/my-cart",
    },
    {
      icon: <UserIcon />,
      active: window.location.pathname === "/user-profile",
      hidden: false,
      pathname: "/user-profile",
    },
  ];

  const handleLogout = () => {
    clearLocal();
    history.push("/");
  };

  useEffect(() => {
    if (userInfo?.userId) {
      dispatch(getCartItemCount(userInfo?.userId));
    }
  }, []);

  return (
    <div>
      {Loader(loading)}
      <header>
        <div className="container">
          <div className="header-alignment">
            <div className="left-content">
              <FitappLogo />
             
            </div>

            <div className="menu">
                <NavLink to="/trainer-screen">Trainer</NavLink>
                <NavLink to="/workout-page">Workout Plans</NavLink>
                <NavLink to="/meal-plan">Meal Plans</NavLink>
              </div>

            <div className="right-content">
              {getToken("access_token") ? (
                <div className="rounded-icon-group">
                  {headerMenuItems
                    .filter((e) => !e.hidden)
                    .map((item, index) => (
                      <div
                        className={`rounded-icon ${item.active && "active"}`}
                        onClick={() => history.push(item.pathname)}
                      >
                        {item.icon}
                        {item.pathname === "/my-cart" && (
                          <div
                            className={`cart-box-alignment ${
                              item.active && "white-bg"
                            }`}
                          >
                            <p>{cartCount}</p>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              ) : (
                <>
                  <NavLink to="/login">
                    <a>Login</a>
                  </NavLink>
                  <div className="rounded-md-button">
                    {isSubscriptionType() ? (
                       !isSubscribed() && 
                      <NavLink to="/subscription-plans">
                        <button>Subscribe Now</button>
                      </NavLink>
                    ) : (
                      <NavLink to="/workout-page">
                        <button>Plan Details</button>
                      </NavLink>
                    )}
                  </div>
                </>
              )}
            </div>
            <div
              className="mobile-menu"
              onClick={() => setMobileHeader(!mobileHeader)}
            >
              <i className="fa-solid fa-bars"></i>
            </div>
          </div>
        </div>
      </header>
      {mobileHeader && <div className="header-wrapper"></div>}
      <div
        className={
          mobileHeader
            ? "mobile-header mobile-header-show"
            : "mobile-header mobile-header-hidden"
        }
      >
        <div className="app-header-alignment">
          <FitappLogo />
          <i
            className="fa-solid fa-xmark"
            onClick={() => setMobileHeader(false)}
          ></i>
        </div>
        <div className="app-body-content">
          <NavLink onClick={() => setMobileHeader(false)} to="/trainer-screen">
            Trainer
          </NavLink>
          <NavLink onClick={() => setMobileHeader(false)} to="/workout-page">
            Workout Plans
          </NavLink>
          <NavLink onClick={() => setMobileHeader(false)} to="/meal-plan">
            Meal Plans
          </NavLink>
          {getToken("access_token") ? (
            <NavLink onClick={handleLogout} to="/">
              Logout
            </NavLink>
          ) : (
            <NavLink onClick={() => setMobileHeader(false)} to="/login">
              Login
            </NavLink>
          )}
          <NavLink to="/user-profile" onClick={() => setMobileHeader(false)}>
            Subscribe Now
          </NavLink>
        </div>
      </div>
    </div>
  );
}
