import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Login from "../../components/auth/login";
import Signup from "../../components/auth/sign-up";
import ResetPassword from "../../components/auth/resetPassword";
import LoginImg from "../../assets/images/auth-banner.png";
import signupImg from "../../assets/images/signup.png";
import FitnessGoal from "../../components/auth/fitnessGoal";
import TrainerDetails from "../../components/auth/trainerDetails";
import PersonalDetails from "../../components/auth/personalDetails";
import DetaryPreference from "../../components/auth/detaryPreference";
import { getItemFromLocal, ItemFromPayload, setItemToLocal } from "../../utils";
import { clientId, Loader } from "../../utils/helpers";
import api from "../../utils/api";
import { toast } from "react-hot-toast";
import CloseIcon from "../../../src/assets/icons/close.svg";

import "./auth.scss";

export default function Auth() {
  const history = useHistory();
  const userInfo = getItemFromLocal("user");
  const [loading, setLoading] = useState(false);
  const [onboardingQueAns, setOnboardingQueAns] = useState([]);
  const [selectedAns, setSelectedAns] = useState({});
  const [resetemail, setResetEmail] = useState("");
  const [forgotpassword, setFogotpassword] = useState("");

  const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
  const sendVarificationLink = () => {
    if (!EMAIL_REGEX.test(resetemail)) {
      toast.error("Enter valid email.");
    } else {
      api
        .get(`authentication/ResetPassword?email=${resetemail}`)
        .then((response) => {
          toast.success("Link send successfully! Please check your mail!");
          setResetEmail("");
          setFogotpassword(false);
        });
    }
  };


  const getOnboardingQueAns = () => {
    setLoading(true);
    api
      .get(`clients/getOnboardingQuestionsAnswers/${clientId()}`)
      .then((response) => {
        const item = ItemFromPayload(response, "payload");
        setOnboardingQueAns(item);
        // setItemToLocal("onboarding-user", item);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (window.location.pathname === "/onboarding") {
      getOnboardingQueAns();
    }
  }, []);

  const handleNext = (params) => {
    history.push(`/${params}`);
  };

  const getQuesAns = (params) => {
    return onboardingQueAns.filter((key) => key.questionID === params);
  };

  const renderElement = (params) => {
    if (params === "") {
      switch (window.location.pathname) {
        case "/login":
          return <Login setFogotpassword={setFogotpassword}  forgotpassword={forgotpassword} />;
        case "/signUp":
          return <Signup handleNext={handleNext} />;
          // case "/resetpassword":
          //   return <ResetPassword handleNext={handleNext} />; 
        case "/onboarding":
          return (
            <FitnessGoal
              handleNext={handleNext}
              queAns={getQuesAns(1)}
              selectedAns={selectedAns}
              setSelectedAns={setSelectedAns}
              onboardingQueAns={onboardingQueAns}
              userInfo={userInfo}
            />
          );
        default:
          break;
      }
    } else {
      switch (params) {
        case "?goal":

        // case "?training":
        //   return (
        //     <TrainerDetails
        //       handleNext={handleNext}
        //       queAns={getQuesAns(2)}
        //       selectedAns={selectedAns}
        //       setSelectedAns={setSelectedAns}
        //     />
        //   );
        // case "?personal":
        //   return (
        //     <PersonalDetails
        //       handleNext={handleNext}
        //       queAns={getQuesAns(3)}
        //       selectedAns={selectedAns}
        //       setSelectedAns={setSelectedAns}
        //     />
        //   );
        // case "?diet":
        // return (
        //   <DetaryPreference
        //     handleNext={handleNext}
        //     queAns={getQuesAns(4)}
        //     selectedAns={selectedAns}
        //     setSelectedAns={setSelectedAns}
        //   />
        // );
        default:
          break;
      }
    }
  };

  return (
    <>
      {Loader(loading)}
      <div className="login-section">
        <div className="container">
          <div className="login-alignment">
            <div className="login-grid-alignment">
              {renderElement(window.location.search)}
              <div className="login-second-part-alignment">
                <img
                  src={
                    window.location.pathname === "/login" ? LoginImg : signupImg
                  }
                  alt="LoginImg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {forgotpassword && (
        <div className="add-email-modal-alignment">
          <div className="modal-wrapper">
            <div className="add-email-modal-box-alignment trainer-add-align ">
              <div className="add-email-heading-alignment trainer-added-modal">
                <h4>Forgot Password</h4>
                <div
                  className="add-more-alignment"
                  onClick={() => setFogotpassword(!forgotpassword)}
                >
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
              </div>
              <div className="add-email-grid  ">
                <div className="add-email-formControl  ">
                  <div className="form-control  ">
                    <label>Enter registred mail</label>
                    <div className="input-alignment">
                      <input
                        type="text"
                        name="resetemail"
                        value={resetemail}
                        placeholder="email"
                        onChange={(e) => setResetEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="done-button-alignment trainer-added-modal">
                {resetemail ? (
                  <button onClick={() => sendVarificationLink()}>
                    Send Rest Link
                  </button>
                ) : (
                  <button style={{ opacity: 0.5 }}>Send Rest Link</button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
