import React from "react";
import { useHistory } from "react-router-dom";

import FirstStepImage from "../../../assets/images/new-framer.png";
import FirstImage1 from "../../../assets/images/FirstImage.svg";
import SecTeamImage from "../../../assets/images/sec-team.png"
import MealIcon from "../../../assets/icons/meal.svg";

import LiveIcon from "../../../assets/icons/live-icon.svg"
import WeightliftingIcon from "../../../assets/icons/weightlifting.svg";
import { isSubscribed, isSubscriptionType } from "../../../utils/helpers";

import "./trainerDetailsSection.scss";

export default function TrainerDetailsSection() {
  const history = useHistory();
  return (
    <div>
      <div className="container">
        <div className="trainer-all-details-content-alignment">
          <div className="grid">
            <div className="grid-items">
              <div className="left-content-alignment">
                <div className="same-text-style">
                  <h4>Full access to 100+ Fitplans</h4>
                  <div className="all-icon-text-grid-alignment">
                    <div className="icon-text-grid">
                      <div className="icon-text-grid-items">
                        <div className="icon"></div>
                      </div>
                      <div className="icon-text-grid-items">
                        <span>
                          Daily workouts with step-by-step HD video instruction
                        </span>
                      </div>
                    </div>
                    <div className="icon-text-grid">
                      <div className="icon-text-grid-items">
                        <div className="icon"></div>
                      </div>
                      <div className="icon-text-grid-items">
                        <span>
                          Kel tbs on your weights, reps and time and watch your
                          results take shape
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-md-button">
                    {isSubscriptionType() ? (
                       !isSubscribed() && 
                      <button
                        onClick={() => history.push("/subscription-plans")}
                      >
                        Subscribe Now
                      </button>
                    ) : (
                      <button onClick={() => history.push("/workout-all-plan")}>See More Plans</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-items">
              <div className="image-style">
                <img src={FirstStepImage} alt="FirstStepImage" />
              </div>
            </div>
            <div className="button-design">
              <button>
                <span>Workout</span>
                <img src={WeightliftingIcon} alt="WeightliftingIcon" />
              </button>
            </div>
          </div>
          <div className="sec-grid">
            <div className="sec-grid-items">
              <div className="image-style">
                <img src={SecTeamImage} alt="SecTeamImage" />
              </div>
            </div>
            <div className="sec-grid-items">
              <div className="left-content-alignment">
                <div className="same-text-style">
                  <h4>Full access to 100+ Fitplans</h4>
                  <div className="all-icon-text-grid-alignment">
                    <div className="icon-text-grid">
                      <div className="icon-text-grid-items">
                        <div className="icon"></div>
                      </div>
                      <div className="icon-text-grid-items">
                        <span>
                          Daily workouts with step-by-step HD video instruction
                        </span>
                      </div>
                    </div>
                    <div className="icon-text-grid">
                      <div className="icon-text-grid-items">
                        <div className="icon"></div>
                      </div>
                      <div className="icon-text-grid-items">
                        <span>
                          Kel tbs on your weights, reps and time and watch your
                          results take shape
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-md-button">
                    {isSubscriptionType() ? (
                       !isSubscribed() && 
                      <button
                        onClick={() => history.push("/subscription-plans")}
                      >
                        Subscribe Now
                      </button>
                    ) : (
                      <button onClick={() => history.push("/all-meal-plan")}>See More Plans</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="button-design-right">
              <button>
                <img src={MealIcon} alt="MealsIcon" />
                <span>Meal</span>
              </button>
            </div>
          </div>
          <div
            className="grid"
            style={{ borderBottom: "none", paddingBottom: "0" }}
          >
            <div className="grid-items">
              <div className="left-content-alignment">
                <div className="same-text-style">
                  <h4>Available on multiple devices.</h4>
                  <div className="all-icon-text-grid-alignment">
                    <div className="icon-text-grid">
                      <div className="icon-text-grid-items">
                        <div className="icon"></div>
                      </div>
                      <div className="icon-text-grid-items">
                        <span>
                          Daily workouts with step-by-step HD video instruction
                        </span>
                      </div>
                    </div>
                    <div className="icon-text-grid">
                      <div className="icon-text-grid-items">
                        <div className="icon"></div>
                      </div>
                      <div className="icon-text-grid-items">
                        <span>
                          Kel tbs on your weights, reps and time and watch your
                          results take shape
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-md-button">
                    {isSubscriptionType() ? (
                       !isSubscribed() && 
                      <button
                        onClick={() => history.push("/subscription-plans")}
                      >
                        Subscribe Now
                      </button>
                    ) : (
                      <button onClick={() => history.push("/workout-all-plan")}>See More Plans</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-items">
              <div className="image-style">
                <img src={FirstImage1} alt="FirstStepImage" />
              </div>
            </div>
            <div className="button-design">
              <button>
                <span>Live</span>
                <img src={LiveIcon} alt="LiveIcon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
