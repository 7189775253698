import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import Blog from "./blog";
import DownloadFitApp from "./downloadFitApp";
import FitappsTeam from "./fitappsTeam";
import HeroSection from "./heroSection";
import MeetFitappTrainer from "./meetFitappTrainer";
import OurspecializedSection from "./ourspecializedSection";
import SubscribePlans from "./subscribePlans";
import RecommendedPlan from "../workoutPage/recommendedPlan";
import { getItemFromLocal, getToken, ItemFromPayload } from "../../utils";
import { toast } from "react-hot-toast";
import api from "../../utils/api";
import { clientId, isSubscriptionType, Loader } from "../../utils/helpers";

import "./home.scss";
import { useDispatch } from "react-redux";
import { getCartItemCount } from "../../redux/reducer";
// import FaqSection from "../../components/subFooter/faqSection";
// import Emailbox from "../../components/subFooter/emailBox";

//  import Emailbox from "./emailBox";
// import FaqSection from "./faqSection";
export default function Home() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const [loading, setLoading] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [workoutPlans, setWorkoutPlans] = useState([]);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getBannerPlans();
    getSubscriptionPlans();
    if (!isSubscriptionType()) getWorkoutPlans();
  }, [])
  

  useEffect(() => {
    CheckUserSubcription();
  }, []);

  const getSubscriptionPlans = () => {
    setLoading(true);
    api
      .get(`clients/getClientSubscriptionPlan?ClientId=${clientId()}`)
      .then((res) => {
        if (res.status === 200) {
          setSubscriptionPlans(ItemFromPayload(res, "payload"));
          setLoading(false);
        }
      })
      .catch((err) => setLoading(false));
  };

  const CheckUserSubcription = () => {
    setLoading(true);

    if (userInfo?.userId) {
      api
        .post(
          `/userSubscription/CheckUserSubcription?UserID=${userInfo?.userId}`
        )
        .then((response) => {
          if (response?.data?.success) {
            const data = response?.data?.payload
            localStorage.setItem("userSubcription", data);
          setLoading(false);
          }else{
            setLoading(false);
            toast.error(response.data?.error || "Something went wrong!");
          }
        }).catch((error) => {
          setLoading(false);
          toast.error(error.response?.data?.error);
        })
    }
  };

  const getBannerPlans = () => {
    setLoading(true);
    api
      .get(`trainers/getAppTrainers?clientID=${clientId()}`)
      .then((response) => {
        if (response.status === 200) {
          setPlanList(ItemFromPayload(response, "payload"));
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getWorkoutPlans = () => {
    setLoading(true);
    api
      .get(
        `plans/getAppWorkoutPage?ClientId=${clientId()}&UserId=${
          userInfo?.userId || 0
        }`
      )
      .then((response) => {
        if (response.status === 200) {
          const item = ItemFromPayload(response, "payload");
          setWorkoutPlans(item);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleAddToCart = (e, id) => {
    e.stopPropagation();
    if (getToken("access_token")) {
      setLoading(true);
      api
        .post("plans/addToCart", {
          clientID: clientId(),
          planID: id,
          userID: userInfo.userId,
        })
        .then((res) => {
          if (res.data.success) {
            setLoading(false);
            dispatch(getCartItemCount(userInfo.userId));
            toast.success("Plan added to cart successfully");
          } else {
            setLoading(false);
            toast.error(res.data?.error);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response?.data?.error);
        });
    } else {
      history.push("/login");
    }
  };

  return (
    <div>
      {Loader(loading)}
      <HeroSection planList={planList} />
      <FitappsTeam />
      <OurspecializedSection setLoading={setLoading} />
      <MeetFitappTrainer />
      {isSubscriptionType() ? (
        <SubscribePlans subscriptionPlans={subscriptionPlans} />
      ) : (
        <RecommendedPlan
          recommendedPlans={workoutPlans?.recommendedPlans}
          getWorkoutPlans={getWorkoutPlans}
          handleAddToCart={handleAddToCart}
          showButtons
        />
      )}
      <DownloadFitApp />
      <Blog setLoading={setLoading} userInfo={userInfo} />

     

        {/* <FaqSection />
        <Emailbox /> */}
    </div>
  );
}
