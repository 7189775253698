import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import DownloadFitApp from "../home/downloadFitApp";
import FeaturePlanCard from "./featurePlanCard";
import { getItemFromLocal, getToken, ItemFromPayload } from "../../utils";
import { clientId, Loader } from "../../utils/helpers";
import api from "../../utils/api";

import "./featurePlan.scss";
import { toast } from "react-hot-toast";
import { getCartItemCount } from "../../redux/reducer";
import { useDispatch } from "react-redux";

export default function FeaturePlan() {
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const history = useHistory();
  const [workoutPlans, setWorkoutPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  
  useEffect(() => {
    getWorkoutPlans();
  }, [])
  

  const handleAddToCart = (e, id) => {
    e.preventDefault();
    if (getToken("access_token")) {
      setLoading(true);
      api
        .post("plans/addToCart", {
          clientID: clientId(),
          planID: id,
          userID: userInfo.userId,
        })
        .then((res) => {
          if (res.data.success) {
            setLoading(false);
            dispatch(getCartItemCount(userInfo.userId));
            toast.success("Plan added to cart successfully"); 
          } else {
            setLoading(false);
            toast.error(res.data?.error);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response?.data?.error);
        });
    } else {
      history.push("/login");
    }
  };

  const getWorkoutPlans = () => {
    setLoading(true);
    api
      .get(`plans/getAppWorkoutPage?ClientId=${clientId()}&UserId=${userInfo?.userId || 0}`)
      .then((response) => {
        if (response.status === 200) {
          const item = ItemFromPayload(response, "payload");
          setWorkoutPlans(item);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div>
      {Loader(loading)}
      
        <div className="feature-page-banner">
          <h1>All Feature Plans</h1>
        </div>
    
      <FeaturePlanCard
        handleAddToCart={handleAddToCart}
        featurePlans={workoutPlans.featurePlans}
        getWorkoutPlans={getWorkoutPlans}
      />
      <DownloadFitApp />
      <div className="feature-plan-last-spacer"></div>
    </div>
  );
}
