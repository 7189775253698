import React, { useEffect } from "react";

import ActivePlan from "./activePlan";
import WishlistSubscriptionPlan from "./wishlistSubscriptionPlan";

import "./wishlist.scss";

export default function Wishlist() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="container">
        <div className="whislist-banner">
          <h1>My Wishlist</h1>
        </div>
      </div>
      <WishlistSubscriptionPlan />
      <ActivePlan />
    </div>
  );
}
