import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import api from "../../utils/api";
import {
  getItemFromLocal,
  ItemFromPayload,
  urlSearchParams,
} from "../../utils";
import {
  clientId,
  isSubscribed,
  isSubscriptionType,
  Loader,
} from "../../utils/helpers";
import CartDetailsModal from "../myCart/myCartDetails/CartDetailsModal";
import RecommendedMealPlan from "../mealPlan/recommendedMealPlan";
import CardImage from "../../assets/images/child-meal-image.png";
import OpenLockIcon from "../../assets/icons/lock.svg";
import ClosedLockIcon from "../../assets/icons/lock-icon.svg";
import DownloadFitApp from "../home/downloadFitApp";

import "./mealPlanDetails.scss";
import ReactPlayer from "react-player";

export default function MealPlanDetails() {
  const history = useHistory();
  const planId = urlSearchParams("id");
  const userInfo = getItemFromLocal("user");
  const [loading, setLoading] = useState(false);
  const [recommendedPlans, setRecommendedPlans] = useState([]);
  const [planDetails, setPlanDetails] = useState([]);
  const [currentMealPlan, setCurrentMealPlan] = useState({});
  const [currentPlanDetails, setCurrentPlanDetails] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [planId]);

  useEffect(() => {
    getAllMealPlans();
  }, []);

  useEffect(() => {
    if (planId) getMealPlanDetails(planId);
  }, [planId]);

  const getAllMealPlans = () => {
    setLoading(true);
    api
      .get(
        `plans/getMealPlans?ClientId=${clientId()}&UserId=${
          userInfo?.userId || 1
        }`
      )
      .then((response) => {
        if (response.status === 200) {
          // setRecommendedPlans(ItemFromPayload(response, "recommendedPlans"));
          setRecommendedPlans(response?.data?.payload?.recommendedPlans);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getMealPlanDetails = (id) => {
    setLoading(true);
    api
      .get(
        `plans/getDietPlanDetails_v1?PlanID=${id}&UserId=${
          userInfo?.userId || 0
        }&ClientId=${clientId()}`
      )
      .then((response) => {
        if (response.status === 200) {
          const arr = [];
          const item = ItemFromPayload(response, "payload");
          item.weeks.map((a) =>
            a.days.map((b) => b.dietPlanSchedule.map((c) => arr.push(c)))
          );
          setPlanDetails(arr);
          setCurrentPlanDetails(item?.plans);
          setCurrentMealPlan(
            arr?.filter((obj) => obj?.recipe[0]?.videoUrl)[0].recipe[0]
          );
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  };
  return (
    <div>
      {Loader(loading)}
      <div className="meal-plan-all-details-page-content-alignment">
        {planDetails?.filter((obj) => obj?.recipe[0]?.videoUrl)?.length > 0 && (
          <div className="container">
            <div className="grid">
              <div className="grid-items">
                {/* <iframe
                  src={
                    currentMealPlan?.value ||
                    "https://www.youtube.com/embed/JTxsNm9IdYU"
                  }
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe> */}
                <ReactPlayer
                  url={
                    currentMealPlan?.videoUrl ||
                    "https://www.youtube.com/embed/JTxsNm9IdYU"
                  }
                  playing={true}
                  loop={true}
                  controls={true}
                  className="video-player"
                />

                {/* <button className="subscribe-btn mt-10" onClick={() => history.push("/subscription-plans")}>
                            Subscribe Now
                          </button> */}

                           {!currentPlanDetails?.isSubscribed && (
                    <div className="plan-access-button">
                      {isSubscriptionType() ? (
                        !isSubscribed() && (
                          <button className="subscribe-btn mt-10"
                            onClick={() => history.push("/subscription-plans")}
                          >
                            Subscribe Now
                          </button>
                        )
                      ) : (
                        <button className="subscribe-btn mt-10" onClick={() => setIsOpen(true)}>
                          Buy Now{" "}
                        </button>
                      )}
                    </div>
                  )}

              </div>
              <div className="grid-items">
                <div className="light-box">
                  <h5
                    style={{
                      whiteSpace: "nowrap",
                      width: "360px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {currentPlanDetails?.title} Details
                  </h5>
                  <div className="meal-all-card-alignment">
                    <div>
                      {planDetails.map((item) => {
                        if (item.recipe?.length > 0) {
                          return item?.recipe?.map((data) => {
                            console.log("data", item);
                            return (
                              <div
                                className={`main-card ${
                                  !currentPlanDetails?.isSubscribed &&
                                  "locked-plan-videos"
                                }`}
                                onClick={() => setCurrentMealPlan(data)}
                              >
                                <div className="card-grid">
                                  <div className="card-grid-items">
                                    <div className="card-image">
                                      {data?.imageUrl ? (
                                        <img
                                          src={
                                            data?.imageUrl
                                              ? data?.imageUrl
                                              : CardImage
                                          }
                                          alt="CardImage"
                                        />
                                      ) : (
                                        <ReactPlayer
                                          url={data?.videoUrl}
                                          playing={true}
                                          loop={true}
                                          controls={true}
                                          width="100px"
                                          height="50px"
                                        />
                                        // <iframe src={data?.value} title="YouTube video player" frameborder="0" allow="" allowfullscreen></iframe>
                                      )}
                                    </div>
                                  </div>
                                  <div className="card-grid-items">
                                    <div className="all-content-alignment">
                                      <div className="left-content">
                                        <h6>
                                          Week {item?.week} - Day {item?.day}
                                        </h6>

                                        <p>
                                          {data.calories} Calories <br />
                                          {data.prepareTime} min Prepare Time
                                        </p>
                                      </div>
                                      {!currentPlanDetails?.isSubscribed && (
                                        <div className="right-content">
                                          <img
                                            src={
                                              currentPlanDetails?.isSubscribed
                                                ? OpenLockIcon
                                                : ClosedLockIcon
                                            }
                                            alt="LockIcon"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          });
                        }
                      })}
                    </div>
                  </div>
                  {/* {!currentPlanDetails?.isSubscribed && (
                    <div className="plan-access-button">
                      {isSubscriptionType() ? (
                        !isSubscribed() && (
                          <button
                            onClick={() => history.push("/subscription-plans")}
                          >
                            Subscribe Now
                          </button>
                        )
                      ) : (
                        <button onClick={() => setIsOpen(true)}>
                          Buy Now{" "}
                        </button>
                      )}
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <RecommendedMealPlan recommendedPlans={recommendedPlans} />
      <div className="meal-plan-details-bottom-alignment">
        <DownloadFitApp />
      </div>
      {isOpen && (
        <CartDetailsModal
          planDetails={currentPlanDetails}
          setIsOpen={setIsOpen}
          getAllMealPlans={() => getMealPlanDetails(planId)}
        />
      )}
    </div>
  );
}
