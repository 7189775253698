import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-hot-toast";

import SubscriptionImage from "../../assets/images/subscription.png";
import api from "../../utils/api";
import { getItemFromLocal, getToken, ItemFromPayload } from "../../utils";
import { clientId, currencySign, displayRazorpay, Loader } from "../../utils/helpers";

import "./subscriptionPlans.scss";

export default function SubscriptionPlans() {
  const history = useHistory();
  const userInfo = getItemFromLocal("user");
  const [loading, setLoading] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getSubscriptionPlans();
  }, []);

  const getSubscriptionPlans = () => {
    setLoading(true);
    api
      .get(`clients/getClientSubscriptionPlan?ClientId=${clientId()}`)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setSubscriptionPlans(ItemFromPayload(res, "payload"));
        }
      })
      .catch((err) => setLoading(false));
  };

  const isPlanSelected = () => {
    if (Object.keys(selectedPlan).length > 0) return true;
    else toast.error("Please select any plan");
  };

  const isUserAvailable = () => {
    if (getToken()) return isPlanSelected();
    else history.push("/login");
  };

  const handleSubscribeNow = (e) => {
    if (getToken()) {
      displayRazorpay(e, selectedPlan?.finalPrice, '',  handleSubcribePlan)
    } else {
      history.push("/login");
    }
  };

  const handleSubcribePlan = (paymentId) => {
    console.log("----------paymentId", paymentId);
   
    const payload = {
      clientID: clientId(),
      userID: userInfo?.userId,
      planID: null,
      isSubcription: true,
      subcriptionType: selectedPlan.id,
      days: selectedPlan.days,
    };
    if (isUserAvailable()) {
      setLoading(true);
      api
        .post("userSubscription/SubscribeUser", payload)
        .then((res) => {
          if (res?.data?.success) {
            toast.success("Plan subscribed successfully");
            setLoading(false);
            localStorage.setItem("userSubcription", true);
            history.push("/");
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data);
          setLoading(false);
        });
    }
  };
  return (
    <div>
      {Loader(loading)}
      <div className="subscription-plans-all-content-alignment">
        <div className="container">
          <div className="white-box">
            <div className="box-title">
              <h4>Subscription Plans</h4>
              {/* <p>
                Try Fitplan FREE for 7 days. You won't be charged until your
                trial ends. Cancel anytime.
              </p> */}
            </div>
            <div className="grid">
              <div className="grid-items">
                <div className="image-style">
                  <img src={SubscriptionImage} alt="SubscriptionImage" />
                </div>
              </div>
              <div className="grid-items">
                <div className="all-card-alignment">
                  {subscriptionPlans?.map((plan) => (
                     <div
                        className={`card ${
                          selectedPlan.id === plan.id && "selected-plan"
                        }`}
                        onClick={() => setSelectedPlan(plan)}
                      >
                        <div className="left-content">
                          {plan?.discount > 0 ? (
                            <p>
                              <s style={{ color: "#7e818c" }}>
                                {currencySign()}
                                {plan?.price}
                              </s>&nbsp;&nbsp;
                              {currencySign()}
                              {plan?.finalPrice}
                              
                              /{plan.planType}
                            </p>
                          ) : (
                            <p>
                              {currencySign()}
                              {plan?.price}/{plan.planType}
                            </p>
                          )}
                          <span>
                            {plan.planType} billed at {plan?.finalPrice}
                            {currencySign()}/{plan.planType}
                          </span>
                        </div>
                        <div className="right-content">
                          <div className="round"></div>
                        </div>
                      </div>
                  ))}
                  {/* <div className="card">
                    <div className="left-content">
                      <p>${subscriptionPlans.quarterlyPrice}/Quarterly</p>
                      <span>
                        Quarterly billed at {subscriptionPlans.quarterlyPrice}
                        {currencySign()}/every 3 months
                      </span>
                    </div>
                    <div className="right-content">
                      <div className="round"></div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="left-content">
                      <p>${subscriptionPlans.yearlyPrice}/yearly</p>
                      <span>
                        Yearly billed at {subscriptionPlans.yearlyPrice}$/every
                        year
                      </span>
                    </div>
                    <div className="right-content">
                      <div className="round"></div>
                    </div>
                  </div> */}
                </div>
                <div className="rounded-md-button ">
                  <button onClick={handleSubscribeNow}>Subscription</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
