import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { clientId, Loader } from "../../../utils/helpers";
import CloseIcon from "../../../assets/icons/close.svg";
import Logo from "../../../assets/icons/logo.svg";
import { toast } from "react-hot-toast";

import "./resetPassword.scss";
import FitappLogo from "../../fitappLogo";
import axiosApi from "../../../utils/api";

export default function ResetPassword({ handleNext }) {
  const [userData, setUserData] = useState({ Password: "", ConfirmPassword: "" });
  const activeTabKey = window.location.pathname.split("/");
  const location = useLocation().search;
  const history = useHistory();
  const id = new URLSearchParams(location).get("ResetId");
  const [loading, setLoading] = useState(false);

  const [resetemail, setResetEmail] = useState("");
  const [forgotpassword, setFogotpassword] = useState("");

  const HandlerResetdata = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    const payload = {
      resetId: id,
      password: userData?.Password,
      confirmPassword: userData?.Password,
    };
    ResetPasswordFun(payload);
  };

  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const sendVarificationLink = () => {
    if (!EMAIL_REGEX.test(resetemail)) {
      toast.error("Enter valid email.");
    } else {
      setLoading(true);
      const payload = {
        email: resetemail,
      };

      sendPasswordLink(payload);
    }
  };

  const ResetPasswordFun = async (payload) => {
    try {
      axiosApi.post(`authentication/ResetPasswordByLink`, payload).then((response) => {
        setLoading(false);
        if(response?.data=="Password reset link has been expired or invalid!"){
          toast.error(response?.data);

        }else{

          toast.success(response?.data);
          history.push("/login");
        }
      });
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const sendPasswordLink = async (payload) => {
    if (payload?.email) {
      try {
        axiosApi.get(`authentication/ResetPassword?email=${payload?.email}`).then((response) => {
          setLoading(false);

          setResetEmail("");
          setFogotpassword(false);
          toast.success("Link send successfully! Please check your mail!");
        });
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    }
  };

  return (
    <>
      <div>
        {Loader(loading)}

        <div className="sign-up-page-content-left-alignment">
          <FitappLogo />
          {/* <img src={Logo} alt="Logo"/> */}

          <div className="reset-password-form">
            <div className="text-style">
              <h3>Reset Password</h3>
            </div>

            <div className="form">
              <label>New password</label>

              <input name="Password" type="password" value={userData?.Password} placeholder="Password" onChange={(e) => HandlerResetdata(e)} />
            </div>
            <div className="form">
              <label>Confirm password</label>
              <input
                name="ConfirmPassword"
                type="text"
                value={userData?.ConfirmPassword}
                placeholder="Confirm Password"
                onChange={(e) => HandlerResetdata(e)}
              />
            </div>

            <div className="form-resendLink">
              <span onClick={() => setFogotpassword(!forgotpassword)}>Resend link?</span>
            </div>

            <div className="rounded-md-button ">
              {userData?.Password && userData?.ConfirmPassword ? (
                userData?.Password === userData?.ConfirmPassword ? (
                  <button onClick={() => handleSubmit()}>Reset Password</button>
                ) : (
                  <button style={{ opacity: 0.5 }}>Reset Password</button>
                )
              ) : (
                <button style={{ opacity: 0.5 }}>Reset Password</button>
              )}
            </div>

            {forgotpassword && (
              <div className="add-email-modal-alignment ">
                <div className="modal-wrapper">
                  <div className="add-email-modal-box-alignment ">
                    <div className="add-email-heading-alignment trainer-added-modal">
                      <h4>Send new reset link</h4>
                      <div className="add-more-alignment" onClick={() => setFogotpassword(!forgotpassword)}>
                        <img src={CloseIcon} alt="CloseIcon" />
                      </div>
                    </div>
                    <div className="add-email-grid  ">
                      <div className="add-email-formControl  ">
                        <div className="form-control  ">
                          <label>Enter registred mail</label>
                          <div className="input-alignment">
                            <input
                              type="text"
                              name="ingredientName"
                              value={resetemail}
                              placeholder="email"
                              onChange={(e) => setResetEmail(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="done-button-alignment trainer-added-modal">
                      {resetemail ? (
                        <button onClick={() => sendVarificationLink()}>Send Rest Link</button>
                      ) : (
                        <button style={{ opacity: 0.5 }}>Send Rest Link</button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
