import Routes from "./routes";
import { Toaster } from "react-hot-toast";

import "./App.css";
import "./styles/mixins/global.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-phone-input-2/lib/style.css";

function App() {
  return (
    <>
      <Toaster position="top-right" />
      <Routes />
    </>
  );
}

export default App;
