import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";

import PlayIcon from "../../../assets/icons/play.svg";
import { getItemFromLocal, ItemFromPayload } from "../../../utils";
import api from "../../../utils/api";

import {
  clientId,
  isSubscribed,
  isSubscriptionType,
  Loader,
} from "../../../utils/helpers";
import CartDetailsModal from "../../myCart/myCartDetails/CartDetailsModal";

import "./subscribeNow.scss";

export default function SubscribeNow({ planDetails, setPlanDetails }) {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [subscribedPlans, setSubscribedPlans] = useState([]);
  const userInfo = getItemFromLocal("user");
  const [loading, setLoading] = useState(false);

  const handlePurchasePlan = (e, plan) => {
    e.preventDefault();
    setIsOpen(true);
    setPlanDetails(plan);
  };

  useEffect(() => {
    getSubscribedPlans();
  }, []);

  const getSubscribedPlans = () => {
    setLoading(true);
    api
      .get(
        `userSubscription/GetUserActiveSubscription?UserId=${
          userInfo?.userId
        }&ClientId=${clientId()}`
      )
      .then((res) => {
        if (res.status === 200) {
          setSubscribedPlans(ItemFromPayload(res, "payload"));

          setLoading(false);
        }
      })
      .catch((err) => setLoading(false));
  };

  return (
    <div>
      {Loader(loading)}

      <div className="subscribe-now-all-content-alignment">
        <div className="container">
          <div className="subscribe-banner">
            <div className="play-button">
              {/* <img src={PlayIcon} alt="PlayIcon" /> */}
              <iframe
                src={planDetails?.promoUrl}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              {/* <ReactPlayer
                url={planDetails?.promoUrl}
                playing={true}
                loop={true}
                controls={true}
              /> */}
            </div>
          </div>
          <div className="subscribe-button">
            {/* {isSubscriptionType() ? (
                <button onClick={() => history.push("/subscription-plans")}>
                  Subscribe Now
                </button>
              ) : (
              )} */}

            {isSubscriptionType()
              ? !subscribedPlans?.some(
                  (item) => item?.planID === planDetails?.planID
                ) && (
                  <button onClick={() => history.push("/subscription-plans")}>
                    Subscribe Now
                  </button>
                )
              : !subscribedPlans?.some(
                  (item) => item?.planID === planDetails?.planID
                ) && (
                  <button onClick={(e) => handlePurchasePlan(e, planDetails)}>
                    Buy Now
                  </button>
                )}
          </div>
        </div>
      </div>
      {isOpen && (
        <CartDetailsModal planDetails={planDetails} setIsOpen={setIsOpen} />
      )}
    </div>
  );
}
