import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ItemFromPayload } from "../utils";
import api from "../utils/api";
import RequestStates from "../utils/request-states";

const initialState = {
  variable: "",
  loaderRequestState: RequestStates.init,
  cartItemCount: 0,
};

export const getCartItemCount = createAsyncThunk(
  "auth/getCartItemCount",
  async (userId, { rejectWithValue }) => {
    try {
      const res = await api.get(`plans/getCartCount?UserID=${userId}`);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

const webAppData = createSlice({
  name: "webAppData",
  initialState,
  reducers: {
    setVariable(state, action) {
      return {
        ...state,
        variable: action.payload,
      };
    },
  },
  extraReducers: {
    [getCartItemCount.fulfilled]: (state, action) => ({
      ...state,
      cartItemCount: ItemFromPayload(action.payload, "payload", 0),
      loaderRequestState: RequestStates.success,
    }),
    [getCartItemCount.pending]: (state) => ({
      ...state,
      cartItemCount: 0,
      loaderRequestState: RequestStates.loading,
    }),
    [getCartItemCount.rejected]: (state) => ({
      ...state,
      cartItemCount: 0,
      loaderRequestState: RequestStates.error,
    }),
  },
});

export const { setVariable } = webAppData.actions;
export default webAppData.reducer;
