import React from "react";

import ManImage from "../../../assets/images/man.svg";

import "./aboutSection.scss";

export default function AboutSection({ currentTrainer }) {
  return (
    <div>
      <div className="about-section-all-content-alignment-for-page">
      
          <div className="about-banner">
            <div className="about-banner-content-alignment">
              <div className="grid">
                <div className="grid-items">
                  <div>
                    <h4>
                      About <br />
                      {currentTrainer?.name}
                    </h4>
                    <p>{currentTrainer?.personalStory}</p>
                  </div>
                </div>
                <div className="grid-items">
                  <div className="man-image">
                    <img
                      src={ ManImage}
                      alt="ManImage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
       
      </div>
    </div>
  );
}
