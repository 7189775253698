import React, { useEffect, useState } from "react";

import ProfileImage from "../../assets/images/profile-image.png";
import BlogMainImage from "../../assets/images/blog-lg-image.png";
import BlogImage from "../../assets/images/child-blog-img.png";
import SimilarBlog from "./similarBlog";
import api from "../../utils/api";
import { clientId, Loader } from "../../utils/helpers";
import { getItemFromLocal, ItemFromPayload, urlSearchParams } from "../../utils";

import "./blogDetails.scss";

export default function BlogDetails() {
  const userInfo = getItemFromLocal("user");
  const postId = urlSearchParams("id");
  const [loading, setLoading] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);
  const [currentBlogPost, setCurrentBlogPost] = useState({});
  const [similarBlogPost, setSimilarBlogPost] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [postId]);
  
  
  useEffect(() => {
    getAllBlogPosts();
  }, [])

  const getAllBlogPosts = () => {
    setLoading(true);
    api
      .get(`blogs/GetBlogPosts?ClientID=${clientId()}&UserID=${userInfo?.userId || 18}`)
      .then((response) => {
        if (response.status === 200) {
          const item = ItemFromPayload(response, "payload");
          setBlogPosts(item);
          if (postId) {
            const currentPost = item.filter((o) => o.postID.toString() === postId);
            setCurrentBlogPost(currentPost[0]);
            setSimilarBlogPost(item.filter((o) => o.postID.toString() !== postId))
          }
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {Loader(loading)}
      <div className="blog-details-all-content-alignment">
        <div className="container-md-5">
          <div className="blog-details-first-section-alignment">
            <h1>{currentBlogPost?.title}</h1>
            {/* <div className="profile-grid">
              <div className="profile-grid-items">
                <img src={ProfileImage} alt="ProfileImage" />
              </div>
              <div className="profile-grid-items">
                <p>Jimmy Lewin</p>
                <span>Post by Admin</span>
              </div>
            </div> */}
          </div>
        </div>
        <div className="blog-open-image">
          <div className="container-md-6">
            {currentBlogPost?.uploads?.length > 0 && <img src={currentBlogPost?.uploads[0]?.uploadUrl} alt="BlogMainImage" />}
          </div>
        </div>

        <div className="container-md-5">
          <div className="blog-details-sec-content-alignment">
            <p>{currentBlogPost?.bodyText}</p>
          </div>
        </div>
      </div>
      <SimilarBlog similarBlogPost={similarBlogPost} setCurrentBlogPost={setCurrentBlogPost} currentBlogPost={currentBlogPost}/>
    </div>
  );
}
