import React, { useState } from "react";

import CartImg from "../../../assets/images/blog-lg-image.png";
import CartIcon from "../../../assets/icons/shopping-bag.svg";
import TransIcon from "../../../assets/icons/trash.svg";
import ActiveTransIcon from "../../../assets/icons/active-trash.svg";
import CartDetailsModal from "./CartDetailsModal";

import "./myCartDetails.scss";
import { currencySign } from "../../../utils/helpers";

export default function MyCartDetails({ cartData, handleOnDelete, handlePayment, getCartData, handleSubscribeNow }) {
  const [isOpen, setIsOpen] = useState(false);
  const [planDetails, setPlanDetails] = useState({});

  const handleClick = (item) => {
    setIsOpen(true);
    setPlanDetails(item);
  };

  return (
    <div>
      <div className="my-cart-added-content-alignment">
        <div className="container">
          {cartData?.length > 0 ? (
            <>
              <div className="page-title">
                <p>Plans</p>
              </div>
              <div className="my-cart-grid">
                <div className="my-cart-gridItem">
                  {cartData?.length > 0 &&
                    cartData?.map((item) => {
                      return (
                        <div
                          className="plane-box-alignment"
                          // onClick={() => handleClick(item)}
                        >
                          <div className="cart-img-alignment">
                            <img src={item?.bannerUrl || CartImg} alt="CartImg" />
                          </div>
                          <div className="cart-details-alignment">
                            <div className="delete-icon-alignment">
                              <img src={TransIcon} alt="TransIcon" />
                            </div>
                            <div className="active-icon-alignment" onClick={(e) => handleOnDelete(e, item?.planID)}>
                              <img src={ActiveTransIcon} alt="ActiveTransIcon" />
                            </div>
                            <h4>{item?.name}</h4>
                            {item?.discount > 0 ? (
                              <p>
                                {currencySign()}
                                {(item?.basePrice * (100 - item?.discount)) / 100}
                                &nbsp;&nbsp;
                                <s style={{ color: "#7e818c" }}>
                                  {currencySign()}
                                  {item?.basePrice}
                                </s>
                                &nbsp;
                                {/* ({item?.discount}% OFF) */}
                              </p>
                            ) : (
                              <p>
                                {currencySign()}
                                {item?.basePrice}
                              </p>
                            )}{" "}
                            {/* <p>{currencySign()}{item?.basePrice}</p> */}
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div className="my-cart-gridItem">
                  <div className="summary-section-alignment">
                    <div className="heading-alignment">
                      <h4>Summary</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tincidunt feugiat felis, </p>
                    </div>
                    <div className="summary-details-alignment">
                      {cartData?.length > 0 &&
                        cartData?.map((item) => (
                          <div className="summary-details-list-alignment">
                            <p>{item?.name}</p>
                            <span>
                              {currencySign()}
                              {item?.basePrice}
                            </span>
                            {/* <span>{currencySign()}{item?.basePrice}</span> */}
                          </div>
                        ))}
                      {/* <div className="summary-details-list-alignment">
                      <p>Promo Code</p>
                      <span>No</span>
                    </div> */}
                      <div className="summary-details-list-alignment">
                        <p>Sub Total</p>
                        <span>
                          {currencySign()}
                          {cartData?.length > 0 && cartData[0].subTotal}
                        </span>
                      </div>
                      <div className="summary-details-list-alignment">
                        <p>Discount</p>
                        <span>
                          {currencySign()}
                          {cartData?.length > 0 && cartData[0]?.totalDiscount}
                        </span>
                      </div>
                    </div>
                    <div className="total-alignment">
                      <p>Total Cost</p>
                      <p>
                        {currencySign()}
                        {cartData?.length > 0 && cartData[0]?.total} USD
                      </p>
                    </div>
                  </div>
                  <div className="payment-button-alignment">
                    <button  onClick={handleSubscribeNow}>Payment</button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="title-alignment">
              <img src={CartIcon} alt="" />
              <span>Cart is Empty</span>
            </div>
          )}
          {isOpen && <CartDetailsModal planDetails={planDetails} setIsOpen={setIsOpen} getData={getCartData} />}
        </div>
      </div>
    </div>
  );
}
