import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import FeaturePlanImage from "../../../assets/images/feature-plan.png";
import { currencySign, isSubscriptionType } from "../../../utils/helpers";
import CartDetailsModal from "../../myCart/myCartDetails/CartDetailsModal";

import "./featurePlans.scss";

export default function FeaturePlans({ handleAddToCart, featurePlans,getWorkoutPlans }) {
  const [isOpen, setIsOpen] = useState(false);
  const [planDetails, setPlanDetails] = useState({});

  const handlePurchasePlan = (e, plan) => {
    e.preventDefault();
    setIsOpen(true);
    setPlanDetails(plan);
  };

  return (
    <div>
      <div className="feature-plans-all-content-alignment-for-page">
        <div className="container">
          <div className="page-title">
            <h5>Feature Plans</h5>
            <NavLink to={"/workout-all-plan"}>
              <p>See All</p>
            </NavLink>
          </div>
          <div className="grid">
            {featurePlans?.length > 0 &&
              featurePlans?.slice(0, 3).map((item) => (
                <div className="grid-col">
                <div className="grid-items">
                  <NavLink to={`/workout-plan-details?id=${item.planID}`}>
                    <div className="card-image">
                      <img
                        src={item.bannerUrl2 || FeaturePlanImage}
                        alt="FeaturePlanImage"
                      />
                    </div>
                    <div className="card-details">
                      <div>
                        <h4>{item?.title}</h4>
                        {isSubscriptionType() && (
                          <div className="two-text-align">
                            <p>
                              Week <span>{item?.weeks}</span>
                            </p>
                            {/* <p>
                            Classes <span>32</span>
                          </p> */}
                          </div>
                        )}
                        <div className="last-text">
                          <p>{item?.name} </p>
                        </div>
                      </div>

                      {!isSubscriptionType() && (
                        <div className="price-button-alignment">
                          <div className="price-alignment">
                          {item?.discount > 0 ? (
                              <h6>
                                {currencySign()}
                                {item?.finalPrice}
                                &nbsp;&nbsp;
                                <s style={{color:"#7e818c"}}>
                                  {currencySign()}
                                  {item?.basePrice}
                                </s>
                                &nbsp;
                                {/* ({item?.discount}% OFF) */}
                              </h6>
                            ) : (
                              <h6>
                                {currencySign()}
                                {item?.basePrice}
                              </h6>
                            )}
                            {/* <h6>{currencySign()} {item?.basePrice}</h6> */}
                          </div>

                          {item?.isSubscribed ? (
                            <div className="purchase-button-alignment">
                              <button>purchased</button>
                            </div>
                          ) : (
                            <div className="buy-cancle-button-alignment">
                              <>
                                <button
                                  onClick={(e) => handlePurchasePlan(e, item)}
                                >
                                  Buy Now
                                </button>
                                <button
                                  onClick={(e) =>
                                    handleAddToCart(e, item.planID)
                                  }
                                >
                                  Add to cart
                                </button>
                              </>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </NavLink>
                </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {isOpen && (
        <CartDetailsModal planDetails={planDetails} setIsOpen={setIsOpen} getWorkoutPlans={getWorkoutPlans}/>
      )}
    </div>
  );
}
