import React, { useEffect, useState } from "react";

import api from "../../../utils/api";
import PlusIcon from "../../../assets/icons/plus.svg";
import MinusIcon from "../../../assets/icons/minus.svg";
import { ItemFromPayload } from "../../../utils";
import { clientId } from "../../../utils/helpers";

import "./faqSection.scss";

export default function FaqSection() {
  const [faqOpen, setFaqOpen] = useState([]);
  const [loading, setLoading] = useState(false);
  const [faqData, setFaqData] = useState([]);

  useEffect(() => {
    getFAQ();
  }, []);

  const getFAQ = () => {
    setLoading(true);
    api
      .get(`clients/getClientFAQ?ClientId=${clientId()}`)
      .then((response) => {
        const item = ItemFromPayload(response, "payload");
        setFaqData(item);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const removetag = (tag) => {
    const removeIndex = faqOpen.findIndex((item) => item === tag);
    faqOpen.splice(removeIndex, 1);
    setFaqOpen([...faqOpen]);
  };

  return (
    <div>
      <div className="faq-all-content-alignment">
        {faqData?.length > 0 && (
          <div className="container-md">
            <div className="section-title">
              <h1>Frequently ask Questions</h1>
            </div>
            <div className="all-faq-content-alignment">
              {faqData?.map((faq, i) => {
                return (
                  <div className="main-faq">
                    <div className="faq-header" onClick={() => (faqOpen.includes(i) ? removetag(i) : setFaqOpen([...faqOpen, i]))}>
                      <p>{faq?.value}</p>
                      <img src={faqOpen.includes(i) ? MinusIcon : PlusIcon} alt="PlusIcon" />
                    </div>
                    <div className={faqOpen.includes(i) ? "faq-body faq-show" : "faq-body faq-hidden"}>
                      <ul className="message ">
                        <li>{faq?.answers[0]?.value}</li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
