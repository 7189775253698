import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import { getItemFromLocal, setItemToLocal } from "../../../utils";
import PersonalDetails from "../personalDetails";
import api from "../../../utils/api";

import "./fitnessGoal.scss";

export default function FitnessGoal({ onboardingQueAns, userInfo }) {
  const [questionNumber, setQuestionNumber] = useState(0);
  const [saveAnswer, setSaveAnswer] = useState([]);
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    const userOnboarding = getItemFromLocal("onboarding-user");
    if (userOnboarding?.length > 0) {
      setQuestionNumber(userOnboarding?.length);
      setSaveAnswer(userOnboarding);
    }
  }, []);

  const HandleAnswer = () => {
    if (
      saveAnswer?.find(
        (obj) =>
          obj?.questionId ===
          onboardingQueAns[questionNumber]?.answers[0]?.questionID
      )
    ) {
      let answerData = {
        clientId: answer?.clientId,
        userId: userInfo?.userId,
        questionId: answer?.questionId,
        question: onboardingQueAns[questionNumber]?.value,
        answerId: answer?.answerId,
        value: answer?.value,
      };
      let newAnswerData = saveAnswer?.map((obj) => {
        if (
          obj?.questionId ===
          onboardingQueAns[questionNumber]?.answers[0]?.questionID
        ) {
          return answerData;
        } else {
          return obj;
        }
      });
      setSaveAnswer(newAnswerData);
      setQuestionNumber(questionNumber + 1);
      let answeradded = newAnswerData?.find(
        (obj) =>
          obj?.questionId ===
          onboardingQueAns[questionNumber + 1]?.answers[0]?.questionID
      );
      if (answeradded) {
        setAnswer(answeradded);
      } else {
        setAnswer("");
      }
      setItemToLocal("onboarding-user", newAnswerData);
    } else {
      let answerData = {
        clientId: answer?.clientId,
        userId: userInfo?.userId,
        questionId: answer?.questionId,
        question: onboardingQueAns[questionNumber]?.value,
        answerId: answer?.answerId,
        value: answer?.value,
      };
      setSaveAnswer([...saveAnswer, answerData]);
      setQuestionNumber(questionNumber + 1);
      setAnswer("");
      setItemToLocal("onboarding-user", [...saveAnswer, answerData]);
      if (questionNumber + 1 === onboardingQueAns?.length) {
        api
          .post("authentication/SaveUserOnboardingAnswers", [
            ...saveAnswer,
            answerData,
          ])
          .then((response) => {
            toast.success("Profile updated successfully!");
            setItemToLocal("isQuestioned", true);
          })
          .catch((error) => {
            toast.error(error.response?.data?.error);
          });
      }
    }
  };

  const HandleBackAnswer = () => {
    setQuestionNumber(questionNumber - 1);
    let answeradded = saveAnswer?.find(
      (obj) => obj?.question === onboardingQueAns[questionNumber - 1]?.value
    );
    setAnswer(answeradded);
  };
  return (
    <div>
      {questionNumber < onboardingQueAns?.length && !getItemFromLocal("isQuestioned")? (
        <div className="fitness-goal-all-content-alignment">
          <h4>{onboardingQueAns[questionNumber]?.value}</h4>
          <div className="child-button-alignment">
            {onboardingQueAns[questionNumber]?.answers.map((ans) => {
              return (
                <button
                  className={
                    answer?.answerId === ans?.answerID ? "active-button" : ""
                  }
                  onClick={() =>
                    setAnswer({
                      ...ans,
                      answerId: ans?.answerID,
                      questionId: ans?.questionID,
                      clientId: ans?.clientID,
                    })
                  }
                >
                  {ans?.value}
                </button>
              );
            })}
          </div>
          <div className="rounded-md-button ">
            {questionNumber != 0 && (
              <button onClick={() => HandleBackAnswer()}>Back</button>
            )}
            {answer ? (
              <button onClick={() => HandleAnswer()}>Next</button>
            ) : (
              <button style={{ opacity: 0.5 }}>Next</button>
            )}
          </div>
        </div>
      ) : (
        <PersonalDetails userInfo={userInfo} />
      )}
    </div>
  );
}
