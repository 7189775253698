import React from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";

import HeroBanner from "../../../assets/images/hero-banner.png";
import { isSubscribed, isSubscriptionType } from "../../../utils/helpers";

import "./heroSection.scss";

export default function HeroSection({ planList }) {
  const history = useHistory();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  
  return (
    <div className="hero-slider-content-alignment">
      <div >
        <Slider {...settings}>
          {planList?.length > 0 &&
            planList?.map((plan) => {
              return (
                <div>
                  <div className="main-hero-banner">
                    <div className="image-div">
                      <img
                        src={plan.bannerUrl || HeroBanner}
                        alt="HeroBanner"
                      />


                      <div className="image-blur"></div>
                    </div>
                    <div className="hero-content-alignment">
                      <div>
                        <h2>{plan.aboutMe}</h2>
                        <p>{plan.personalStory}</p>
                        <div className="rounded-white-button">
                          {isSubscriptionType() ? (
                            !isSubscribed() &&
                            <button
                              onClick={() =>
                                history.push("/subscription-plans")
                              }
                            >
                              Subscribe Now
                            </button>
                          ) : (
                            <button onClick={() => history.push("/trainer-screen")}>
                              View all plans
                            </button>
                          )}
                        </div>      
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
}
