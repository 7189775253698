import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import DownloadFitApp from "../home/downloadFitApp";
import FeaturePlans from "./featurePlans";
import LewinPlans from "./lewinPlans";
import RecommendedPlan from "./recommendedPlan";
import api from "../../utils/api";
import { getItemFromLocal, getToken, ItemFromPayload } from "../../utils";
import {
  clientId,
  isSubscribed,
  isSubscriptionType,
  Loader,
} from "../../utils/helpers";

import "./workoutPage.scss";
import { toast } from "react-hot-toast";
import { getCartItemCount } from "../../redux/reducer";
import { useDispatch } from "react-redux";

export default function WorkoutPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const [workoutPlans, setWorkoutPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getWorkoutPlans();
  }, []);

  const getWorkoutPlans = () => {
    setLoading(true);
    api
      .get(
        `plans/getAppWorkoutPage?ClientId=${clientId()}&UserId=${
          userInfo?.userId || 0
        }`
      )
      .then((response) => {
        if (response.status === 200) {
          const item = ItemFromPayload(response, "payload");
          setWorkoutPlans(item);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleAddToCart = (e, id) => {
    e.preventDefault();
    if (getToken("access_token")) {
      setLoading(true);
      api
        .post("plans/addToCart", {
          clientID: clientId(),
          planID: id,
          userID: userInfo.userId,
        })
        .then((res) => {
          if (res.data.success) {
            setLoading(false);
            dispatch(getCartItemCount(userInfo.userId));
            toast.success("Plan added to cart successfully");
          } else {
            setLoading(false);
            toast.error(res.data?.error);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response?.data?.error);
        });
    } else {
      history.push("/login");
    }
  };

  return (
    <div>
      {Loader(loading)}
    
        <div className="workout-banner-image">
          <div className="workout-content-alignment">
            {/* <p>Jimmy Lewin’s</p> */}
            <h4>Train with the best fitness Coach</h4>
            <div className="rounded-white-button">
              {isSubscriptionType() ? (
                !isSubscribed() && (
                  <button onClick={() => history.push("/subscription-plans")}>
                    Subscribe Now
                  </button>
                )
              ) : (
                <button onClick={() => history.push("/workout-all-plan")}>
                  Plan Details
                </button>
              )}
            </div>
          </div>
        </div>
      
      <FeaturePlans
        handleAddToCart={handleAddToCart}
        featurePlans={workoutPlans?.featurePlans}
        getWorkoutPlans={getWorkoutPlans}
      />
      <RecommendedPlan
        recommendedPlans={workoutPlans?.recommendedPlans}
        getWorkoutPlans={getWorkoutPlans}
      />
      <LewinPlans
        getWorkoutPlans={getWorkoutPlans}
        handleAddToCart={handleAddToCart}
        trainersPlans={workoutPlans?.trainersPlans}
      />
      <DownloadFitApp />
      <div className="workout-last-section-spacer"></div>
    </div>
  );
}
