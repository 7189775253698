import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-hot-toast";

import CloseIcon from "../../../../assets/icons/close.svg";
import CartImg from "../../../../assets/images/blog-lg-image.png";
import { getItemFromLocal, getToken } from "../../../../utils";
import { clientId, currencySign, displayRazorpay, Loader } from "../../../../utils/helpers";
import api from "../../../../utils/api";

import "./CartDetailsModal.scss";

export default function CartDetailsModal({ planDetails, setIsOpen, getData, getAllMealPlans, getWorkoutPlans, getUserWorkoutPlans }) {
  const history = useHistory();
  const userInfo = getItemFromLocal("user");
  const [loading, setLoading] = useState(false);
  

  const isUserLoggedIn = () => {
    if (getToken()) return true;
    else history.push("/login");
  };

  const handleSubscribeNow = (e) => {
    if (getToken()) {
      displayRazorpay(e, planDetails?.finalPrice, planDetails?.planID, handlePayment)
    } else {
      history.push("/login");
    }
  };

  const handlePayment = (paymentId, planId ) => {
    console.log('paymentId*********', paymentId);
    if (isUserLoggedIn()) {
      setLoading(true);
      const payload = {
        clientID: clientId(),
        userID: userInfo.userId,
        planID: planId.toString(),
        isSubcription: false,
        days: 0,
      };
      api
        .post("userSubscription/SubscribeUser", payload)
        .then((res) => {
          if (res?.data?.success) {
            toast.success("Plan successfully purchased!");
            if (getData) getData();
            if (getUserWorkoutPlans) getUserWorkoutPlans();
            if (getWorkoutPlans) getWorkoutPlans();
            if (getAllMealPlans) getAllMealPlans();
            setLoading(false);
            setIsOpen(false);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data);
          setLoading(false);
        });
    }
  };

  return (
    <>
      {Loader(loading, 1000)}
      <div className="cart-details-modal-alignment">
        <div className="cart-details-modal-wrapper">
          <div className="cart-details-modal">
            <div className="cart-header-alignment">
              <h4>Plans</h4>
              <div className="close-button-alignment" onClick={() => setIsOpen(false)}>
                <img src={CloseIcon} alt="CloseIcon" />
              </div>
            </div>
            <div className="cart-modal-body-alignment">
              <div className="cart-modal-grid ">
                <div className="cart-modal-gridItem">
                  <div className="modal-plane-box-alignment">
                    <div className="cart-img-alignment">
                      <img src={planDetails?.bannerUrl || CartImg} alt="CartImg" />
                    </div>
                    <div className="cart-details-alignment">
                      <h4>{planDetails?.title}</h4>
                      {planDetails?.discount > 0 ? (
                        <p>
                          {currencySign()}
                          {planDetails?.finalPrice}
                          &nbsp;&nbsp;
                          <s style={{ color: "#7e818c" }}>
                            {currencySign()}
                            {planDetails?.basePrice}
                          </s>
                          &nbsp;
                          {/* ({planDetails?.discount}% OFF) */}
                        </p>
                      ) : (
                        <p>
                          {currencySign()}
                          {planDetails?.basePrice}
                        </p>
                      )}{" "}
                      {/* <p>{currencySign()}{planDetails?.basePrice}</p> */}
                    </div>
                  </div>
                </div>
                <div className="cart-modal-gridItem">
                  <div className="summary-section-alignment">
                    <div className="heading-alignment">
                      <h4>Summary</h4>
                      {/* <p>{planDetails?.description}</p> */}
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tincidunt feugiat felis, </p>
                    </div>
                    <div className="summary-details-alignment">
                      <div className="summary-details-list-alignment">
                        <p>{planDetails?.title}</p>
                        {/* <span>{currencySign()}{planDetails?.basePrice}</span> */}

                        <span>
                          {currencySign()}
                          {planDetails?.basePrice}
                        </span>
                      </div>
                      {/* <div className="summary-details-list-alignment">
                        <p>Promo Code</p>
                        <span>No</span>
                      </div> */}
                      <div className="summary-details-list-alignment">
                        <p>Discount </p>

                        <span>
                          {currencySign()}
                          {planDetails?.discount}
                        </span>
                      </div>
                      {/* <div className="summary-details-list-alignment">
                        <p>Sub Total</p>

                        <span>
                          {currencySign()}
                          {planDetails?.finalPrice}
                        </span>
                      </div> */}
                    </div>
                    <div className="total-alignment">
                      <p>Total Cost</p>

                      <p>
                        {currencySign()}
                        {planDetails?.finalPrice}
                      </p>
                    </div>
                  </div>
                  <div className="payment-button-alignment">
                    <button onClick={(e) => handleSubscribeNow(e)}>Payment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
