import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import Footer from "../components/footer";
import Header from "../components/header";
import SubFooter from "../components/subFooter";
import AboutFitApp from "./aboutFitApp";
import AllMealPlan from "./allMealPlan";
import Blog from "./blog";
import BlogDetails from "./blogDetails";
import CheckoutPage from "./checkoutPage";
import Faq from "./faq";
import FeaturePlan from "./featurePlan";
import Home from "./home";
import Auth from "./auth";
import MealPlan from "./mealPlan";
import MealPlanDetails from "./mealPlanDetails";
import PaymentSuccesful from "./paymentSuccesful";
import PlanDetails from "./planDetails";
import PlanProgressDetails from "./planProgressDetails";
import PrivacyPolicy from "./privacyPolicy";
import StrengthPage from "./strengthPage";
import SubscriptionPlans from "./subscriptionPlans";
import TeamPage from "./teamPage";
import TermsConditions from "./termsConditions";
import TrainerScreen from "./trainerScreen";
import UserProfile from "./userProfile";
import wishlist from "./wishlist";
import WorkoutPage from "./workoutPage";
import WorkoutPlan from "./workoutPlan";
import AuthHOC from "../hoc/Auth";
import RedirectHOC from "../hoc/Redirect";
import NoPageFound from "./noPageFound";
import MyCart from "./myCart";
import ResetPassword from "../components/auth/resetPassword";


export default function Routes() {
  return (
    <>
      <BrowserRouter>
        <RouteWrapper exact={true} path="/" component={Home} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/login" component={RedirectHOC(Auth)} layout={AuthLayout} />
        <RouteWrapper exact={true} path="/signup" component={RedirectHOC(Auth)} layout={AuthLayout} />
        <RouteWrapper exact={true} path="/resetpassword" component={ResetPassword} layout={ResetPasswordLayout} />
        <RouteWrapper exact={true} path="/onboarding" component={Auth} layout={AuthLayout} />
        <RouteWrapper exact={true} path="/plan-details" component={PlanDetails} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/workout-page" component={WorkoutPage} layout={ChildLayout} />
        <RouteWrapper exact={true} path="/workout-all-plan" component={FeaturePlan} layout={ChildLayout} />
        <RouteWrapper exact={true} path="/meal-plan" component={MealPlan} layout={ChildLayout} />
        <RouteWrapper exact={true} path="/workout-plan-details" component={WorkoutPlan} layout={ChildLayout} />
        <RouteWrapper exact={true} path="/all-meal-plan" component={AllMealPlan} layout={ChildLayout} />
        <RouteWrapper exact={true} path="/meal-plan-details" component={MealPlanDetails} layout={ChildLayout} />
        <RouteWrapper exact={true} path="/wishlist" component={wishlist} layout={ChildLayout} />
        <RouteWrapper exact={true} path="/blog-details" component={BlogDetails} layout={ChildLayout} />
        <RouteWrapper exact={true} path="/privacy-policy" component={PrivacyPolicy} layout={ChildLayout} />
        <RouteWrapper exact={true} path="/terms-conditions" component={TermsConditions} layout={ChildLayout} />
        <RouteWrapper exact={true} path="/team-page" component={TeamPage} layout={ChildLayout} />
        <RouteWrapper exact={true} path="/license" component={Faq} layout={ChildLayout} />
        <RouteWrapper exact={true} path="/about-fitapp" component={AboutFitApp} layout={ChildLayout} />
        <RouteWrapper exact={true} path="/strength-page" component={StrengthPage} layout={ChildLayout} />
        <RouteWrapper exact={true} path="/blog" component={Blog} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/trainer-screen" component={TrainerScreen} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/plan-progress-details" component={PlanProgressDetails} layout={ChildLayout} />
        <RouteWrapper exact={true} path="/checkout-page" component={CheckoutPage} layout={AuthLayout} />
        <RouteWrapper exact={true} path="/subscription-plans" component={SubscriptionPlans} layout={AuthLayout} />
        <RouteWrapper exact={true} path="/payment-succesful" component={PaymentSuccesful} layout={AuthLayout} />
        <RouteWrapper exact={true} path="/user-profile" component={UserProfile} layout={AuthLayout} />
        <RouteWrapper exact={true} path="/my-cart" component={AuthHOC(MyCart)} layout={ChildLayout} />
        {/* <Route path="*" component={NoPageFound} /> */}
      </BrowserRouter>
    </>
  );
}

const DefaultLayout = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);

const ChildLayout = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);
const AuthLayout = ({ children }) => (
  <>
    <div className="auth-layout">
      <Header />
      {children}
    </div>
  </>
);

const ResetPasswordLayout = ({ children }) => (
  <>
    <div className="ResetPassword-layout">
      {children}
    </div>
  </>
);
function RouteWrapper({ component: Component, layout: Layout, auth, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
