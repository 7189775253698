import React, { useEffect, useState } from "react";
import "./aboutFitApp.scss";
import api from "../../utils/api";
import { clientId, Loader } from "../../utils/helpers";
import { ItemFromPayload, urlSearchParams } from "../../utils";

export default function AboutFitApp() {
  const [aboutData, setAboutData] = useState([]);
  const [loading, setLoading] = useState(false);
  const theme = urlSearchParams("theme");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getAboutData();
  }, []);

  const getAboutData = () => {
    setLoading(true);
    api
      .get(`clients/getAppSetting?ClientId=${clientId()}`)
      .then((response) => {
        if (response.status === 200) {
          let payloadData = ItemFromPayload(response, "payload");
          let filterData = payloadData?.filter((item) => item?.keyID === 1);
          setAboutData(filterData[0]?.value);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {Loader(loading)}
      <div className="about-fit-app-conditions-all-content-alignment" style={{backgroundColor:theme == "black" ? "#000000":"none"}}>
        <div className="container">
          <div className="terms-conditions-banner">
            <h1>About Fitapp</h1>
          </div>
        </div>
        <div className="first-section-content-alignment">
          <div className="container-md-5">
            <p dangerouslySetInnerHTML={{ __html: aboutData }} style={{color:theme == "black" ? "#FFFFFF":"#5E5E5E"}}/>
          </div>
        </div>
      </div>
    </div>
  );
}
