import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import SmallblogImage from "../../../assets/images/small-blog.png";
import "./blogCard.scss";

export default function BlogCard({ blogPosts }) {
  const history = useHistory();

  return (
    <div>
      <div className="small-blog-card-all-content-alignment">
        <div className="container">
          <div className="grid">
            {blogPosts?.slice(2, blogPosts?.length).map((blog) => {
              return (
                <div className="grid-items" onClick={() => history.push(`/blog-details?id=${blog.postID}`)}>
                  {/* <NavLink to={`/blog-details?id=${blog.postID}`}> */}
                  <div className="card-image">
                    <img src={blog.uploads.length > 0 ? blog.uploads[0].uploadUrl : SmallblogImage} alt="SmallblogImage" />
                  </div>
                  <div className="card-details">
                    <h4>{blog.title}</h4>
                    <p>{blog.bodyText}</p>
                    {/* <span>Learn More</span> */}
                  </div>
                  {/* </NavLink> */}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
