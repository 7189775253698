import { Backdrop, CircularProgress } from "@mui/material";
import { getItemFromLocal } from ".";
import FitappLogo from "../components/fitappLogo";

const userInfo = getItemFromLocal("user");

export const Loader = (isLoading = false, zIndex = 1) => (
  <Backdrop open={isLoading} sx={{ zIndex: zIndex }}>
    <CircularProgress sx={{ color: "black" }} />
  </Backdrop>
);

export const isSubscriptionType = () => {
  return process.env.REACT_APP_IS_SUBSCRIPTION_TYPE !== "false";
};
export const isSubscribed = () => {
  return localStorage.getItem("userSubcription") === "true";
};
export const clientId = () => {
  return Number(process.env.REACT_APP_CLIENT_ID);
};

export const currencySign = () => {
  return process.env.REACT_APP_CURRENCY_SIGN;
};

export const bindNameInput = (value) => {
  var regex = new RegExp("^[^a-zA-Z0-9_ ]$");
  var key = String.fromCharCode(!value.charCode ? value.which : value.charCode);
  if (regex.test(key)) {
    value.preventDefault();
    return false;
  }
};

export const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export const displayRazorpay = async (e, amount, id, callBack) => {
  e.preventDefault();

  if (userInfo) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const options = {
      key: "rzp_test_x26TDJSiei1WOk",
      amount: Math.round(amount) * 100,
      currency: "INR",
      name: "FitNative",
      description: "Payment for easy upgrade",
      image: <FitappLogo /> || "",
      notify: {
        sms: true,
        email: true,
      },
      prefill: {
        name: userInfo?.name,
        contact: userInfo?.phone,
        email: userInfo?.email,
      },
      handler: async function (response) {
        if (response?.razorpay_payment_id) {
          callBack(response?.razorpay_payment_id, id);
        }
      },
      theme: {
        color: "#1479FF",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", function (response) {
      alert(response.error.reason);
    });
    paymentObject.open();
  }
};
