import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import FeatureImage from "../../../assets/images/all-feature-image.png";
import CartDetailsModal from "../../myCart/myCartDetails/CartDetailsModal";
import ShareIcon from "../../../assets/icons/share.svg";
import { currencySign, isSubscriptionType } from "../../../utils/helpers";

import "./allfeaturePlan.scss";

export default function AllfeaturePlan({
  handleAddToCart,
  featurePlans,
  getAllMealPlans,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [planDetails, setPlanDetails] = useState({});

  const handlePurchasePlan = (e, plan) => {
    e.preventDefault();
    setIsOpen(true);
    setPlanDetails(plan);
  };

  return (
    <div>
      <div className="all-feature-plan-details-content-alignment">
        <div className="container">
          <div className="page-title">
            <h3>all feature Plans</h3>
          </div>
          <div className="main-card">
            {featurePlans?.map((plan) => {
              return (
                <div className="grid-col">
                <div className="main-card-grid-items">
                  <NavLink to={`/meal-plan-details?id=${plan.planID}`}>
                    <div className="main-relative">
                      <div className="card-image">
                        <img
                          src={plan?.bannerUrl || FeatureImage}
                          alt="FeatureImage"
                        />
                      </div>
                      {/* <div className="share-button">
                        <img src={ShareIcon} alt="ShareIcon" />
                      </div> */}
                    </div>
                    <div className="card-details">
                      <h4>{plan?.title}</h4>
                      {isSubscriptionType() && (
                        <div>
                          <div className="two-text">
                            <p>
                              Weeks <span>{plan?.weeks}</span>
                            </p>
                            <p>{/* Meals <span>20</span> */}</p>
                          </div>
                          <div className="last-text">
                            <p>{plan?.name}</p>
                          </div>
                        </div>
                      )}
                      {!isSubscriptionType() && (
                        <div className="price-button-alignment">
                          <div className="price-alignment">
                            {plan?.discount > 0 ? (
                              <h6>
                                {currencySign()}
                                {plan?.finalPrice}
                                &nbsp;&nbsp;
                                <s style={{ color: "#7e818c" }}>
                                  {currencySign()}
                                  {plan?.basePrice}
                                </s>
                                &nbsp;
                                {/* ({plan?.discount}% OFF) */}
                              </h6>
                            ) : (
                              <h6>
                                {currencySign()}
                                {plan?.basePrice}
                              </h6>
                            )}
                          </div>
                          {plan?.isSubscribed ? (
                            <div className="purchase-button-alignment">
                              <button>purchased</button>
                            </div>
                          ) : (
                            <div className="buy-cancle-button-alignment">
                              <>
                                <button
                                  onClick={(e) => handlePurchasePlan(e, plan)}
                                >
                                  Buy Now
                                </button>
                                <button
                                  onClick={(e) =>
                                    handleAddToCart(e, plan?.planID)
                                  }
                                >
                                  Add to cart
                                </button>
                              </>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </NavLink>
                </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {isOpen && (
        <CartDetailsModal
          planDetails={planDetails}
          setIsOpen={setIsOpen}
          getAllMealPlans={getAllMealPlans}
        />
      )}
    </div>
  );
}
