import React, { useEffect } from 'react'
import './paymentSuccesful.scss';
import PaymentSuccesfulImage from '../../assets/images/sucess.svg';
import PaymentTypeModal from '../../components/paymentTypeModal';
export default function PaymentSuccesful() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])
  return (
    <div>
        <div className='payment-succesful-all-content-alignment'>
            <div className='container'>
                <div className='payment-succesful-modal'>
                    <div className='payment-box'>
                        <h1>Payment Successful</h1>
                        <div className='image-box-center-alignment'>
                            <img src={PaymentSuccesfulImage} alt="PaymentSuccesfulImage"/>
                        </div>
                        <p>
                            Please Download your FitApp 
                            and Access the All Purchasing Plans to Continue.
                        </p>
                        <div className='rounded-md-button'>
                            <button>Download</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PaymentTypeModal/>
    </div>
  )
}
