import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import api from "../../utils/api";
import { getItemFromLocal, getToken, ItemFromPayload } from "../../utils";
import { clientId, Loader } from "../../utils/helpers";
import DownloadFitApp from "../home/downloadFitApp";
import AllfeaturePlan from "./allfeaturePlan";

import "./allMealPlan.scss";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getCartItemCount } from "../../redux/reducer";

export default function AllMealPlan() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");

  const [loading, setLoading] = useState(false);
  const [featurePlans, setFeaturePlans] = useState([]);

  const handleAddToCart = (e, id) => {
    e.preventDefault();
    if (getToken("access_token")) {
      setLoading(true);
      api
        .post("plans/addToCart", {
          clientID: clientId(),
          planID: id,
          userID: userInfo.userId,
        })
        .then((res) => {
          if (res.data.success) {
            setLoading(false);
            dispatch(getCartItemCount(userInfo.userId));
            toast.success("Plan added to cart successfully");
          } else {
            setLoading(false);
            toast.error(res.data?.error);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response?.data?.error);
        });
    } else {
      history.push("/login");
    }
  };

  const getAllMealPlans = () => {
    setLoading(true);
    api
      .get(
        `plans/getMealPlans?ClientId=${clientId()}&UserId=${
          userInfo?.userId || 1
        }`
      )
      .then((response) => {
        if (response.status === 200) {
          // setFeaturePlans(ItemFromNewPayload(response, "featurePlans"));
          setFeaturePlans(response?.data?.payload?.featurePlans);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  
  useEffect(() => {
    getAllMealPlans();
  }, [])
  

  return (
    <div>
      {Loader(loading)}
      <div className="">
        <div className="all-meal-plan-banner">
          <h1>All feature Plans</h1>
        </div>
      </div>
      <AllfeaturePlan
        handleAddToCart={handleAddToCart}
        featurePlans={featurePlans}
        getAllMealPlans={getAllMealPlans}
      />
      <DownloadFitApp />
      <div className="all-meal-plan-bottom-section-align"></div>
    </div>
  );
}
